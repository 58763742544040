import Bugsnag from '@bugsnag/js';
import parse from 'parse-link-header';
import ApiError from './ApiError';
import request from './request';
import refreshAccessToken from '../../domain/auth/services/refreshAccessToken';
import {FORBIDDEN_STATUS_CODE, UNAUTHORIZED_STATUS_CODE} from './constants';
import {parseErrorResponse} from './utils';

export function handleError(error, response) {
  if (response) {
    const apiError = new ApiError(
      `Request failed with status ${response.status}.`,
      error,
      response.status
    );
    Bugsnag.notify(apiError);
    throw apiError;
  } else {
    const apiError = new ApiError(error.toString(), error, 'REQUEST_FAILED');
    Bugsnag.notify(apiError);
    throw apiError;
  }
}

let isRefreshing = false;
let refreshQueue = [];

async function handleForbidden(parsedResponse, apiSettings, isAuthenticated) {
  if (
    isAuthenticated ||
    (parsedResponse.error === 'invalid_grant' &&
      parsedResponse.error_description.includes('refresh token'))
  ) {
    apiSettings.logout();
    throw new Error('Your session has been expired. Please login again.');
  }
}

async function handleUnauthorized({url, options, apiSettings}) {
  if (!apiSettings.refreshToken) {
    apiSettings.logout();
    throw new Error('Your session has been expired. Please login again.');
  }

  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      refreshQueue.push(async (accessToken, error) => {
        if (accessToken) {
          resolve(
            request(url, options, {
              ...apiSettings,
              accessToken,
            })
          );
        }

        reject(error);
      });
    });
  }

  isRefreshing = true;

  try {
    const tokens = await refreshAccessToken(apiSettings);
    apiSettings.updateTokens(tokens.access_token, tokens.refresh_token, apiSettings.rememberMe);

    refreshQueue.forEach((cb) => cb(tokens.access_token));
    return request(url, options, {
      ...apiSettings,
      accessToken: tokens.access_token,
    });
  } catch (error) {
    refreshQueue.forEach((cb) => cb(null, error));
  } finally {
    refreshQueue = [];
    isRefreshing = false;
  }

  return undefined;
}

export async function handleFailedResponse(response, {url, options, apiSettings, isAuthenticated}) {
  const {status} = response;

  if (status === UNAUTHORIZED_STATUS_CODE && isAuthenticated) {
    await handleUnauthorized({url, options, apiSettings});
  }

  const parsedResponse = await parseErrorResponse(response);
  if (status === FORBIDDEN_STATUS_CODE) {
    await handleForbidden(parsedResponse, apiSettings, isAuthenticated);
  }

  throw parsedResponse;
}

export async function parseJSONResponse(response) {
  // compose meta into a response for a paginated request
  if (response.headers?.get('link')) {
    const parsedPagination = parse(response.headers.get('link'));
    return {
      meta: {
        ...parsedPagination,
        totalCount: Number(response.headers.get('x-total-count')),
        totalPages: Number(response.headers.get('x-total-pages')),
      },
      data: await response.json(),
    };
  }

  return response.json();
}

export async function parseBlobResponse(response) {
  const contentDisposition = response.headers.get('content-disposition').split(';')[1];
  const startIndex = contentDisposition.indexOf('"');
  const fileName = contentDisposition.slice(startIndex + 1, contentDisposition.length - 1);
  return {
    fileName,
    blob: await response.blob(),
  };
}
