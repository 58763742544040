import {useState, useEffect} from 'react';
import useFetch from '../../../hooks/useFetch';
import {fetchNewsletterService} from '../service';
import {handleErrorAlert} from '../../../utils/handleErrorAlert';
import useRouter from '../../../hooks/useRouter';
import useAlert from '../../alerts/useAlert';

function useFetchNewsletter() {
  const {query} = useRouter();
  const {showAlert} = useAlert();
  const [data, setData] = useState();

  const {run: fetchNewsletterRequest} = useFetch(fetchNewsletterService, {throwError: true});

  useEffect(
    function () {
      async function fetchNewsletter(id) {
        try {
          const response = await fetchNewsletterRequest(id);
          if (query.fromDraft) {
            response.status = null;
          }
          setData(response);
        } catch (error) {
          handleErrorAlert(error, showAlert);
        }
      }

      const queryId = query.fromDraft || query.id;
      if (queryId) {
        fetchNewsletter(queryId);
      }
    },
    [fetchNewsletterRequest, query.fromDraft, query.id]
  );

  return [data];
}

export default useFetchNewsletter;
