import {Transforms} from 'slate';
import {ReactEditor} from 'slate-react';

export const createLinkButtonNode = (href, text, id) => ({
  type: 'linkButton',
  href,
  children: [{text}],
  id,
});

export const insertLinkButton = (editor, url, id) => {
  if (!url) return;

  const link = createLinkButtonNode(url, 'Click here', id);

  ReactEditor.focus(editor);

  Transforms.insertNodes(editor, link, {select: true});
};
