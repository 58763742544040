const withLinks = (editor) => {
  const {isInline} = editor;

  editor.isInline = (element) =>
    element.type === 'link' || element.type === 'linkButton' ? true : isInline(element);

  return editor;
};

export default withLinks;
