const rgbToHex = (rgb) => {
  const a = rgb.replace(/[^\d,]/g, '').split(',');
  // eslint-disable-next-line no-bitwise
  return `#${((1 << 24) + (+a[0] << 16) + (+a[1] << 8) + +a[2]).toString(16).slice(1)}`;
};

export const convertStyle = (style, href) => {
  if (!style) return;
  const borderColorSliceIndex = style.border.indexOf('rgb');

  return {
    ...style,
    backgroundColor: rgbToHex(style.backgroundColor),
    color: rgbToHex(style.color),
    borderColor: rgbToHex(style.border.slice(borderColorSliceIndex)),
    border: style.border.slice(0, borderColorSliceIndex),
    fontSize: parseInt(style.fontSize, 10),
    borderRadius: parseInt(style.borderRadius, 10),
    textAlign: style.textAlign?.toUpperCase() || 'CENTER',
    url: href,
  };
};

const LinkButton = ({
  attributes,
  element,
  children,
  allButons,
  hasButtonContext,
  buttonContext,
  setButtons,
  setActiveButton,
}) => {
  console.log('allButons', allButons);
  console.log('element linkbutton', element);

  const foundButton = allButons.find((button) => button.buttonId === element.id);

  let buttonStyle = foundButton?.style;
  if (!foundButton) {
    const convertedStyle = convertStyle(element.style, element.href);

    // setButtons((prevButtons) => [...prevButtons, {buttonNumber, style: element.style}]);
    buttonStyle = buttonStyle || convertedStyle;
  }

  console.log('buttonStyle', buttonStyle);
  console.log('buttonContext', buttonContext);

  return (
    <table width="100%">
      <tr width="100%">
        <td width="100%" align={buttonStyle?.textAlign || 'center'}>
          {/* <td width="100%" align="center"> */}
          <a
            {...attributes}
            href={element.href.url}
            style={{
              ...buttonStyle,
              padding: 10,
              display: 'block',
              textDecoration: 'none',
              textAlign: 'center',
            }}
            onClick={() => {
              if (hasButtonContext) {
                buttonContext.setButtonConfig(buttonStyle);
                buttonContext.setToolbarOpen(true);
                setActiveButton(element.id);
                if (!foundButton) {
                  setButtons((prevButtons) => [
                    ...prevButtons,
                    {buttonId: element.id, style: buttonStyle},
                  ]);
                  console.log('converted', rgbToHex(buttonStyle.backgroundColor));

                  // setActiveButton(element.id);
                }
              }
            }}
          >
            {children}
          </a>
        </td>
      </tr>
    </table>
  );
};

export default LinkButton;
