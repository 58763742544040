import {useState} from 'react';
import PropTypes from 'prop-types';
import Card from '../../../components/Card';
import Button from '../../../components/Button';
import BackButton from '../../../components/BackButton';
import ROUTES from '../../../Router/routes';
import ActionButton from '../../../components/ActionButton';

import {newsletterContentPropType} from '../../../utils/prop-types/newsletter';
import styles from './NewsletterTemplates.module.scss';
import useRouter from '../../../hooks/useRouter';
import useFetch from '../../../hooks/useFetch';
import {deleteTemplateService, fetchTemplatesService} from '../service';
import {Icon} from '../../../components/SlateEditor/components';
import CardHeader from '../../../components/Card/CardHeader';
import Modal from '../../../components/Modal';
import {LANDING_URL} from '../../../utils/constants';
import ActionsDropdown from '../../../components/ActionsDropdown';
import ActionDropdownItem from '../../../components/ActionsDropdown/ActionDropdownItem';

const NewsletterTemplates = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState();
  const {run: fetchTemplatesRequest, data, loading} = useFetch(fetchTemplatesService, {
    immediate: true,
  });
  const {run: deleteTemplateRequest, loading: deleteLoading} = useFetch(deleteTemplateService, {
    throwError: true,
  });

  const {history} = useRouter();

  const handleTemplateDelete = async () => {
    await deleteTemplateRequest(templateToDelete.id);
    await fetchTemplatesRequest();
    setIsDeleteModalOpen(false);
  };

  return (
    <div>
      <Card
        loading={loading}
        header={
          <CardHeader
            title="Newsletter Templates"
            text={
              <p style={{marginTop: '12px'}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />o find out more
                information about newsletter templates please read out our{' '}
                <a
                  href={`${LANDING_URL}/how-to-forms`}
                  title="Click here for more details"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  tutorial
                </a>
              </p>
            }
          >
            {' '}
            {data?.length && (
              <ActionButton
                onClick={() => {
                  history.push({
                    pathname: ROUTES.newsletters.templateEditor,
                    state: {isCreateTemplateMode: true},
                  });
                }}
              >
                Create a new Template
              </ActionButton>
            )}
          </CardHeader>
        }
      >
        {!loading && (
          <div className={styles.container}>
            {data.length ? (
              data.map((template) => (
                <div className={styles.templateWrapper}>
                  <div
                    style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
                  >
                    <div style={{fontWeight: 600, fontSize: 18}}>{template.name}</div>
                    {/* <div style={{display: 'flex', gap: 10}}>
                      <div
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          history.push({
                            pathname: ROUTES.newsletters.create,
                            state: {template, isEditTemplateMode: true},
                          });
                        }}
                      >
                        <Icon>edit</Icon>
                      </div>
                      <div
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          setTemplateToDelete(template);
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        <Icon>delete</Icon>
                      </div>
                    </div> */}
                    <ActionsDropdown
                    // dotStyles={{
                    //   width: 4,
                    //   height: 4,
                    //   boxShadow: '8px 0 #aaadc0, 16px 0 #aaadc0',
                    // }}
                    // buttonStyles={{rotate: '90deg', width: 20}}
                    // menuListStyles={{marginTop: 5, marginLeft: -180}}
                    >
                      {/* <ActionDropdownItem
                        onSelect={() => {
                          history.push({
                            pathname: ROUTES.newsletters.templateEditor,
                            state: {template},
                          });
                        }}
                      >
                        Use
                      </ActionDropdownItem> */}
                      <ActionDropdownItem
                        onSelect={() => {
                          history.push({
                            pathname: ROUTES.newsletters.templateEditor,
                            state: {template, isEditTemplateMode: true},
                          });
                        }}
                      >
                        Edit
                      </ActionDropdownItem>
                      <ActionDropdownItem
                        onSelect={() => {
                          setTemplateToDelete(template);
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        Delete
                      </ActionDropdownItem>
                    </ActionsDropdown>
                  </div>
                  {/* <div
                    dangerouslySetInnerHTML={{__html: template.content}}
                    style={{border: ' 1px solid black', padding: 15}}
                  />
                  <div style={{display: 'flex', justifyContent: 'center', marginTop: 30}}>
                    <Button
                      type="button"
                      variant="outlined"
                      color="green"
                      onClick={() => {
                        history.push({
                          pathname: ROUTES.newsletters.create,
                          state: {template},
                        });
                      }}
                    >
                      Use this template
                    </Button>
                  </div> */}
                </div>
              ))
            ) : (
              <div>
                <div style={{fontSize: 20, fontWeight: 600, textAlign: 'center', marginBottom: 20}}>
                  There are no templates yet!
                </div>
                <div style={{textAlign: 'center', color: '#676c85', marginBottom: 30}}>
                  Start creating your own!
                </div>
                <ActionButton
                  onClick={() => {
                    history.push({
                      pathname: ROUTES.newsletters.templateEditor,
                      state: {isCreateTemplateMode: true},
                    });
                  }}
                >
                  Create your first template
                </ActionButton>
              </div>
            )}
          </div>
        )}
        <Modal
          onRequestClose={() => {
            setIsDeleteModalOpen(false);
          }}
          isOpen={isDeleteModalOpen}
        >
          <div style={{textAlign: 'center', fontSize: 20, marginBottom: 30}}>
            Are you sure you want to delete the template <b>{templateToDelete?.name}</b>?
          </div>
          <div style={{display: 'flex', gap: 20}}>
            <Button
              variant="outlined"
              color="grey"
              onClick={() => setIsDeleteModalOpen(false)}
              full
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="red"
              onClick={handleTemplateDelete}
              loading={deleteLoading}
              full
            >
              Delete
            </Button>
          </div>
        </Modal>
      </Card>
    </div>
  );
};

NewsletterTemplates.propTypes = {
  data: newsletterContentPropType,
  createFromDraft: PropTypes.func.isRequired,
};

export default NewsletterTemplates;
