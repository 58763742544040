const endpoint = '/user-profile';
const changeEmailEndpoint = '/change-account-email';

export const ENDPOINTS = {
  fetch: endpoint,
  update: endpoint,
  cancel: '/cancel',
  confirmEmail: (confirmationId) => `/confirm-user-profile-email/${confirmationId}`,
  findPendingProfileEmail: changeEmailEndpoint,
  initProfileEmailChange: (email) => `${endpoint}/${email}`,
};
