export const ENDPOINTS = {
  automationOverview: '/automation-overview',
  getAutomationConfig: (id) => `/automation-config/${id}`,
  automationConfig: '/automation-config',
  automationToolset: '/automation-toolset',
  updateActiveStatus: (data) => `/automation-config/update-status/${data.id}?status=${data.status}`,
  checkAutomationReferences: (id) => `/automation-config/check-references/${id}`,
  getAutomationInitiators: (id) => `/automation-config/initiators/${id}`,
  newAutomationRevision: (id) => `/automation-config/new-version/${id}`,
  newAutomationRevisionWithRedirect: (id) => `/automation-config/new-version-with-redirect/${id}`,
};
