import {jsx} from 'slate-hyperscript';
import {Node} from 'slate';
import {renderToStaticMarkup} from 'react-dom/server';
import StaticEmailNode from '../StaticEmailNode';
import {getObjectKeyFromURL} from './files';

const CSSstring = (string) => {
  const cssJson = `{"${string.replace(/; /g, '", "').replace(/: /g, '": "').replace(';', '')}"}`;

  const obj = JSON.parse(cssJson);

  const keyValues = Object.keys(obj).map((key) => {
    const camelCased = key.replace(/-[a-z]/g, (g) => g[1].toUpperCase());
    return {[camelCased]: obj[key]};
  });
  return Object.assign({}, ...keyValues);
};

export const serialize = (node, customTypes) => {
  const emailReactTree = <StaticEmailNode node={node} customTypes={customTypes} />;
  return renderToStaticMarkup(emailReactTree);
};

export const deserialize = (el) => {
  if (el.nodeType === 3) {
    return el.textContent;
  }
  if (el.nodeType !== 1) {
    return null;
  }

  let children = Array.from(el.childNodes).map(deserialize);

  if (children.length === 0) {
    children = [{text: ''}];
  }

  const generatedStyle = el.style?.cssText && CSSstring(el.style.cssText);

  // if (el.dataset.type === 'linkButton' && el.nodeName !== 'A') return jsx('fragment', {}, children);

  switch (el.nodeName) {
    // Elements:
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'P':
      return jsx(
        'element',
        {
          type: 'paragraph',
          align: el.style?.textAlign,
          style: generatedStyle,
          backgroundColor: generatedStyle?.backgroundColor,
          dataType: el.dataset.type,
        },
        children
      );
    case 'DIV':
      return jsx(
        'element',
        {
          type: 'fragment',
          align: el.style?.textAlign,
          style: generatedStyle,
          backgroundColor: generatedStyle?.backgroundColor,
          dataType: el.dataset.type,
        },
        children
      );
    case 'A':
      if (el.style?.color) {
        return jsx(
          'element',
          {
            type: 'linkButton',
            href: el.getAttribute('href'),
            style: generatedStyle,
            id: el.dataset.id,
          },
          children
        );
      }
      return jsx(
        'element',
        {
          type: 'link',
          url: el.getAttribute('href'),
          tag: el.getAttribute('data-id'),
          align: el.style?.textAlign,
          style: generatedStyle,
          backgroundColor: generatedStyle?.backgroundColor,
          dataType: el.dataset.type,
        },
        children
      );

    case 'H1':
      return jsx(
        'element',
        {
          type: 'heading-one',
          align: el.style?.textAlign,
          style: generatedStyle,
          backgroundColor: generatedStyle?.backgroundColor,
          dataType: el.dataset.type,
        },
        children
      );
    case 'H2':
      return jsx(
        'element',
        {
          type: 'heading-two',
          align: el.style?.textAlign,
          style: generatedStyle,
          backgroundColor: generatedStyle?.backgroundColor,
          dataType: el.dataset.type,
        },
        children
      );
    case 'OL':
      return jsx(
        'element',
        {
          type: 'numbered-list',
          align: el.style?.textAlign,
          style: generatedStyle,
          backgroundColor: generatedStyle?.backgroundColor,
          dataType: el.dataset.type,
        },
        children
      );
    case 'UL':
      return jsx(
        'element',
        {
          type: 'bulleted-list',
          align: el.style?.textAlign,
          style: generatedStyle,
          backgroundColor: generatedStyle?.backgroundColor,
          dataType: el.dataset.type,
        },
        children
      );
    case 'LI':
      return jsx(
        'element',
        {
          type: 'list-item',
          align: el.style?.textAlign,
          style: generatedStyle,
          backgroundColor: generatedStyle?.backgroundColor,
          dataType: el.dataset.type,
        },
        children
      );
    case 'BLOCKQUOTE':
      return jsx(
        'element',
        {
          type: 'block-quote',
          align: el.style?.textAlign,
          style: generatedStyle,
          backgroundColor: generatedStyle?.backgroundColor,
          dataType: el.dataset.type,
        },
        children
      );
    case 'TABLE':
      if (el.dataset.type === 'linkButton') {
        const linkButton = [...Node.nodes({children})].find((nodeEntry) => {
          const [node] = nodeEntry;
          return node?.type === 'linkButton';
        })[0];

        return jsx(
          'element',
          {
            type: 'linkButton',
            href: el.dataset.href,
            style: generatedStyle,
            id: el.dataset.id,
          },
          linkButton.children
        );
      }
      if (el.dataset.type === 'image') {
        return jsx(
          'element',
          {
            type: 'image',
            url: el.dataset.url,
            style: generatedStyle || {},
            objectKey: el.dataset.objectKey || getObjectKeyFromURL(el.dataset.url),
          },
          [{text: ''}]
        );
      }
      return jsx(
        'element',
        {
          type: 'table',
          style: generatedStyle,
          backgroundColor: generatedStyle?.backgroundColor,
          dataType: el.dataset.type,
          width: '100%',
        },
        children
      );
    case 'TBODY':
      return jsx(
        'element',
        {
          type: 'tbody',
          style: generatedStyle,
          backgroundColor: generatedStyle?.backgroundColor,
          dataType: el.dataset.type,
        },
        children
      );
    case 'TR':
      return jsx(
        'element',
        {
          type: 'table-row',
          style: generatedStyle,
          backgroundColor: generatedStyle?.backgroundColor,
          dataType: el.dataset.type,
        },
        children
      );
    case 'TD':
      return jsx(
        'element',
        {
          type: 'table-cell',
          style: generatedStyle,
          backgroundColor: generatedStyle?.backgroundColor,
          dataType: el.dataset.type,
        },
        children
      );

    // Leafs:
    case 'STRONG':
      return jsx('text', {bold: true}, children);
    case 'EM':
      return jsx('text', {italic: true}, children);
    case 'U':
      return jsx('text', {underline: true}, children);
    case 'CODE':
      return jsx('text', {code: true}, children);
    case 'SPAN':
      return jsx(
        'text',
        {fontSize: el.style?.fontSize ? el.style?.fontSize : '16px', color: el.style?.color},
        children
      );

    default:
      return el.textContent;
  }
};
