const senderDomain = '/sender-domain';
const senderEmail = '/sender-email-address';

export const ENDPOINTS = {
  deleteDomain: senderDomain,
  fetchDomain: senderDomain,
  setupDomain: senderDomain,
  fetchDomainSettings: `${senderDomain}/settings`,

  fetchSenderOptions: senderEmail,
  changeSenderName: senderEmail,
  initSenderEmailChange: (email) => `${senderEmail}/${email}`,
  confirmEmail: (confirmationId) => `/confirm-sender-email/${confirmationId}`,
};
