import PropTypes from 'prop-types';
import styles from './Label.module.scss';
import Asterisk from '../../Asterisk';

function Label({children, required}) {
  return (
    <span className={styles.label}>
      {children}
      {required && <Asterisk />}
    </span>
  );
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
};

export default Label;
