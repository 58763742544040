/* eslint-disable no-param-reassign */
import React from 'react';
import {useImmerReducer} from 'use-immer';
import set from 'lodash.set';
import {
  fetchSubscriberFieldsService,
  fetchTagsService,
} from '../../settings/subscriber-fields/service';
import useFetch from '../../../hooks/useFetch';

import {loadSignupFormById, createSignupForm, updateSignupForm, deleteSignupForm} from '../service';
import {
  DEFAULT_FORM_DATA_POPUP,
  CUSTOM_FIELD_DEFAULT_DATA,
  DEFAULT_INLINE_FORM_DATA,
  DEFAULT_FORM_DATA_SLIDER,
} from '../constants';
import {updateFullState, setFormType, addField, updateFieldsList} from './actions';
import useRouter from '../../../hooks/useRouter';
import ROUTES from '../../../Router/routes';

const stub = () => {
  throw new Error('SubscriptionFormBuilderContext is not initialized yet');
};

export const SubscriptionFormBuilderContext = React.createContext({
  setSelectedElement: stub,
  resetSelectedElement: stub,
  // general | header | field | button | body | footer | add_new_field
  selectedElement: 'general',
  subscriberFields: [],
  subscriberTags: [],
  loadForm: stub,
  loading: false,
  createForm: stub,
  updateForm: stub,
  deleteForm: stub,
  setFormPreviewShowModal: stub,
  showFormPreviewModal: stub,
  setShowFormCreatedModal: stub,
  showFormCreatedModal: stub,
  showAddNewFieldModal: stub,
  setShowAddNewFieldModal: stub,
  showAddNewTagModal: stub,
  setShowAddNewTagModal: stub,
  serverFormDataError: stub,
  formState: DEFAULT_FORM_DATA_SLIDER,
  formStatePopup: DEFAULT_FORM_DATA_POPUP,
  formStateInline: DEFAULT_INLINE_FORM_DATA,
  dispatch: stub,
  handleFormSave: stub,
  handleFormDelete: stub,
  formId: '',
  loadingCreate: false,
  loadingDelete: false,
  loadingUpdate: false,
  setInputList: stub,
  validate: stub,
});

export const useSubscriptionFormBuilderContextFabric = () => {
  const [selectedElement, setSelectedElement] = React.useState('general');

  const {data: subscriberFields} = useFetch(fetchSubscriberFieldsService, {immediate: true});
  const {data: subscriberTags} = useFetch(fetchTagsService, {
    immediate: true,
  });
  const {run: loadForm, loading, data: serverFormData, error: serverFormDataError} = useFetch(
    loadSignupFormById
  );
  const {run: createForm, loading: loadingCreate} = useFetch(createSignupForm, {
    showSuccessAlert: true,
  });
  const {run: updateForm, loading: loadingUpdate} = useFetch(updateSignupForm, {
    showSuccessAlert: true,
  });
  const {run: deleteForm, loading: loadingDelete} = useFetch(deleteSignupForm);
  const [showFormPreviewModal, setFormPreviewShowModal] = React.useState(false);
  const [showFormCreatedModal, setShowFormCreatedModal] = React.useState(false);
  const [showAddNewFieldModal, setShowAddNewFieldModal] = React.useState(false);
  const [showAddNewTagModal, setShowAddNewTagModal] = React.useState(false);

  const {query, replace} = useRouter();

  const {formType, formId} = query;

  const resetSelectedElement = React.useCallback(() => {
    setSelectedElement('general');
  }, [setSelectedElement]);

  // eslint-disable-next-line consistent-return
  const [formState, dispatch] = useImmerReducer(
    (draft, action) => {
      if (action.type === 'UPDATE_STATE') {
        if (action.payload) {
          return action.payload;
        }
      }

      if (action.type === 'SET_FORM_TYPE') {
        draft.type = action.payload;
      }

      if (action.type === 'SET_FORM_NAME') {
        draft.name = action.payload;
      }

      if (action.type === 'SET_FORM_CONFIG_PARAM') {
        set(draft, action.payload.path, action.payload.value);
      }

      if (action.type === 'ADD_NEW_FIELD') {
        draft.inputFields.push({
          ...CUSTOM_FIELD_DEFAULT_DATA,
          name: action.payload.name,
          label: action.payload.label,
          dataType: action.payload.dataType,
          placeholder: action.payload.placeholder,
          isRequired: action.payload.isRequired,
          indexPosition: draft.inputFields.length,
        });
      }

      if (action.type === 'ADD_NEW_TAG') {
        if (draft.tags)
          draft.tags.push({
            id: action.payload.id,
            name: action.payload.name,
            indexPosition: draft.tags.length,
          });
        else
          draft.tags = [
            {
              id: action.payload.id,
              name: action.payload.name,
              indexPosition: 0,
            },
          ];
      }

      if (action.type === 'UPDATE_FIELDS_LIST') {
        draft.inputFields = action.payload;
      }

      if (action.type === 'REMOVE_FIELD') {
        draft.inputFields = draft.inputFields.filter((field) => field.name !== action.payload);
      }

      if (action.type === 'REMOVE_TAG') {
        draft.tags = draft.tags.filter((field) => field.name !== action.payload);
      }
    },

    formType === 'popup'
      ? DEFAULT_FORM_DATA_POPUP
      : formType === 'inline'
      ? DEFAULT_INLINE_FORM_DATA
      : DEFAULT_FORM_DATA_SLIDER
  );

  // Set default fields once loaded
  React.useEffect(() => {
    if (formState?.inputFields?.length === 0 && subscriberFields?.length && !loading) {
      subscriberFields
        .filter((f) => f.isMandatory)
        .forEach((f) => {
          dispatch(addField(f.asciiName, f.name, f.dataType, f.name, true));
        });
    }
  }, [dispatch, formState, subscriberFields, loading]);

  // Set server form state once data loaded
  React.useEffect(() => {
    const dataWithTags =
      serverFormData?.tags?.length > 0
        ? {
            ...serverFormData,
            tags: subscriberTags?.length
              ? serverFormData.tags.map(
                  (tagId) => subscriberTags.filter((tag) => tag.id === tagId)[0]
                )
              : [],
          }
        : serverFormData;

    dispatch(updateFullState(dataWithTags));
  }, [dispatch, serverFormData]);

  // Set form type once URL query is parsed
  React.useEffect(() => {
    const camelType = (camelCase) => {
      return camelCase
        .replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase())
        .trim();
    };
    if (formType) {
      dispatch(setFormType(formType.toLocaleUpperCase()));
      dispatch({type: 'SET_FORM_NAME', payload: `My ${camelType(formType)} Form`});
    }
  }, [formType, dispatch]);

  // Load form if ID passed to URL
  React.useEffect(() => {
    if (formId && formState.id !== formId) {
      loadForm(formId);
    }
  }, [loadForm, formId, formState]);

  const validate = React.useCallback(() => {
    const field = formState.inputFields.find((f) => !f.placeholder && !f.label);
    if (field) {
      setSelectedElement(`field.${field.name}`);
      return false;
    }

    return true;
  }, [formState, setSelectedElement]);

  const handleFormSave = React.useCallback(async () => {
    if (!validate()) {
      return;
    }

    const formStateWithTags = {
      ...formState,
      tags: formState?.tags?.length ? formState.tags.map((tag) => tag.id) : [],
    };

    if (formState.id) {
      updateForm(formStateWithTags);
    } else {
      const newFormData = await createForm(formStateWithTags);
      if (newFormData) {
        dispatch(updateFullState(newFormData));
        setShowFormCreatedModal(true);
        replace(ROUTES.signupForms.edit.replace(':formId', newFormData.id));
      }
    }
  }, [validate, replace, dispatch, createForm, formState, updateForm]);

  const handleFormDelete = React.useCallback(async () => {
    await deleteForm(formState.id);
    replace(ROUTES.signupForms.index);
  }, [deleteForm, formState, replace]);

  const setInputList = React.useCallback(
    (newInputFields) => {
      dispatch(updateFieldsList(newInputFields));
    },
    [dispatch]
  );

  const handleFormReset = React.useCallback(async () => {
    const defaultFormData = {
      ...(formState.type === 'POPUP'
        ? DEFAULT_FORM_DATA_POPUP
        : formState.type === 'INLINE'
        ? DEFAULT_INLINE_FORM_DATA
        : DEFAULT_FORM_DATA_SLIDER),
      id: formState.id,
      name: formState.name,
      type: formState.type,
      inputFields: [
        {
          dataType: 'TEXT',
          indexPosition: 0,
          isRequired: true,
          label: 'Email',
          name: 'email',
          placeholder: 'Email',
        },
      ],
    };
    await updateForm(defaultFormData);
    dispatch(updateFullState(defaultFormData));
    return defaultFormData;
  }, [updateForm, formState]);

  return {
    selectedElement,
    setSelectedElement,
    resetSelectedElement,
    subscriberFields: subscriberFields || [],
    subscriberTags: subscriberTags || [],
    loadForm,
    loading,
    createForm,
    updateForm,
    deleteForm,
    setFormPreviewShowModal,
    showFormPreviewModal,
    serverFormDataError,
    formState,
    dispatch,
    handleFormSave,
    handleFormDelete,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    setInputList,
    validate,
    handleFormReset,
    showFormCreatedModal,
    setShowFormCreatedModal,
    showAddNewFieldModal,
    setShowAddNewFieldModal,
    showAddNewTagModal,
    setShowAddNewTagModal,
  };
};
