import {useContext, useState} from 'react';
import {useLocation} from 'react-router';
import BackButton from '../../../components/BackButton';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import CardHeader from '../../../components/Card/CardHeader';
import Input from '../../../components/forms/Input';
import Modal from '../../../components/Modal';
import SlateEditor from '../../../components/SlateEditor';
import useFetch from '../../../hooks/useFetch';
import useRouter from '../../../hooks/useRouter';
import ROUTES from '../../../Router/routes';
import NewsletterContext from '../context';
import Toolbar from '../newsletter/Toolbar/Toolbar';
import {saveAsTemplateService, updateTemplateService} from '../service';

const TemplateEditor = () => {
  const {history} = useRouter();
  const location = useLocation();

  const newsletterContext = useContext(NewsletterContext);

  const [templateName, setTemplateName] = useState(location?.state?.template?.name);
  const [templateValue, setTemplateValue] = useState(location?.state?.template?.content);
  const [isTemplateModalOpen, setTemplateModalOpen] = useState(false);
  const isTemplateEditMode = location?.state?.isEditTemplateMode;
  const isTemplateCreateMode = location?.state?.isCreateTemplateMode;

  const {run: saveAsTemplateRequest, loading: loadingSaveTemplate} = useFetch(
    saveAsTemplateService,
    {throwError: true}
  );

  const {
    run: updateTemplateRequest,
    loading: loadingUpdateTemplate,
  } = useFetch(updateTemplateService, {throwError: true});

  async function handleSaveTemplate() {
    await saveAsTemplateRequest({name: templateName, content: templateValue});
    history.push(ROUTES.newsletters.templates);
  }

  async function handleUpdateTemplate() {
    await updateTemplateRequest({
      id: location?.state?.template?.id,
      name: templateName,
      content: templateValue,
    });
    history.push(ROUTES.newsletters.templates);
  }

  return (
    <div style={{display: 'flex'}}>
      <div style={{flex: 1}}>
        <BackButton noMargin to={ROUTES.newsletters.templates}>
          All Templatets
        </BackButton>

        <Card
          header={
            <CardHeader title={templateName || 'New Template'}>
              <Button
                type="button"
                variant="outlined"
                color="green"
                onClick={() => setTemplateModalOpen(true)}
              >
                {isTemplateEditMode ? 'Update' : 'Create'}
              </Button>
            </CardHeader>
          }
        >
          <SlateEditor
            content={templateValue}
            onContentChange={setTemplateValue}
            placeholder="Hi {{first_name}},"
            showAsciiInput
            canAddTagsToLinks
            hasButtonContext
            buttonContext={newsletterContext}
          />
          <Modal
            onRequestClose={() => {
              setTemplateModalOpen(false);
              setTemplateName('');
            }}
            isOpen={isTemplateModalOpen}
          >
            <Input
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              label="Template name"
            />
            <Button
              disabled={!templateName || !templateValue}
              variant="outlined"
              color="green"
              onClick={isTemplateEditMode ? handleUpdateTemplate : handleSaveTemplate}
              loading={loadingSaveTemplate || loadingUpdateTemplate}
            >
              {isTemplateEditMode ? 'Update ' : 'Create '}Template
            </Button>
          </Modal>
        </Card>
      </div>
      <Toolbar />
    </div>
  );
};

export default TemplateEditor;
