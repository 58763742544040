const billingDetails = '/billing-details';

export const ENDPOINTS = {
  fetchBillingDetails: billingDetails,
  updateBillingDetails: billingDetails,
  addBillingDetails: billingDetails,
  fetchInvoices: '/invoices',
  downloadInvoice: (id) => `/invoices/${id}/pdf`,
  fetchInvoicesCount: '/invoices/count',
  exportCSV: '/export',
  validateVat: '/vat-validate',
};
