const subscriberImport = '/subscriber-import';

export const ENDPOINTS = {
  import: `${subscriberImport}/import`,
  preview: `${subscriberImport}/import-preview`,
  bulkLock: '/subscriber-bulk-lock',
};

export const STEPS = {
  upload: 'upload',
  mapping: 'mapping',
  success: 'success',
};

export const stepsSettings = [
  {
    key: STEPS.upload,
    label: 'Upload your subscribers',
    description: (planLimit) =>
      `Your current plan holds up to ${planLimit} subscribers. If you
        exceed this' amount during import, your Maildroppa account will be paused until
        you either upgrade to an account that supports more subscribers, or remove
        subscribers so that you are within the limits of your current plan.`,
    icon: 'users-2',
  },
  {
    key: STEPS.mapping,
    label: 'Match columns to contact information',
    description: 'Any columns that are not assigned will not be imported.',
    icon: 'list',
  },
  {
    key: STEPS.success,
    label: 'Import',
    icon: 'import',
  },
];
