import {useEffect, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import useFetch from '../../../../hooks/useFetch';
import {fetchSubscribersFilteredService} from '../../../subscribers/service';
import {DEBOUNCE_TIMEOUT} from '../constants';
import {getNewFilterGroup, segmentIsEmpty, mapForForm, unmapForForm} from '../service';
import SegmentFilterGroup from './SegmentFilterGroup';
import SegmentAddFilter from './SegmentAddFilter';
import styles from './SegmentForm.module.scss';
import stylesFilterGroup from './SegmentFilterGroup.module.scss';

function SegmentForm({
  segmentId = null,
  filterGroups: init,
  setFilterGroups: setInit,
  withMatchingLine = true,
  classNameFilterGroupWrapper,
  classNameFilterGroupDivider,
  classNameAddFilterGroupButtonWrapper,
  wrapperStyles,
  addStyles,
}) {
  const [totalCount, setTotalCount] = useState(0);
  const [filterGroups, setFilterGroups] = useState(unmapForForm(init));
  const {run: fetchSubscribersFilteredRequest} = useFetch(fetchSubscribersFilteredService, {
    throwError: true,
  });

  const fetchSubscribersCount = useDebouncedCallback(async (values) => {
    if (values?.filterGroups[0]?.elements.length === 0) {
      return;
    }

    try {
      const res = await fetchSubscribersFilteredRequest(values);
      setTotalCount(res.meta.totalCount);
    } catch (err) {
      setTotalCount(0);
    }
  }, DEBOUNCE_TIMEOUT);

  useEffect(() => {
    const values = mapForForm(filterGroups);
    setInit(values);

    let isFilled;

    values.filterGroups.forEach((group) => {
      group.elements.forEach((element) => {
        isFilled = Object.keys(element).every((key) => element[key]);
      });
    });

    if (!isFilled) {
      return;
    }

    fetchSubscribersCount.callback(values);
  }, [filterGroups]);

  const addFilterGroup = () => {
    setFilterGroups([...filterGroups, getNewFilterGroup()]);
  };

  const deleteFilterGroup = (groupId) => () => {
    setFilterGroups(filterGroups.filter((v) => v.id !== groupId));
  };

  return (
    <div className={styles.formMain}>
      {withMatchingLine && (
        <div className={styles.matchingLine}>
          Filter matches <span className={styles.matchingSelect}>{totalCount}</span> subscribers.
        </div>
      )}
      {filterGroups.map((group, gIndex) => (
        <SegmentFilterGroup
          key={group.id}
          {...{segmentId, filterGroups, setFilterGroups, gIndex}}
          isLastGroup={filterGroups.length === 1}
          deleteFilterGroup={deleteFilterGroup(group.id)}
          classNameWrapper={classNameFilterGroupWrapper}
          classNameDivider={classNameFilterGroupDivider}
          wrapperStyles={wrapperStyles}
        />
      ))}
      <div className={[stylesFilterGroup.filterGroupDivider, styles.filterGroupDivider].join(' ')}>
        <span>OR</span>
      </div>
      <SegmentAddFilter
        onClick={addFilterGroup}
        text="Add Filter Group"
        classNameButtonWrapper={classNameAddFilterGroupButtonWrapper}
        addStyles={addStyles}
      />
    </div>
  );
}

export default SegmentForm;
