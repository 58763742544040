import {useEffect, useState} from 'react';
import {Range} from 'slate';
import {useSlate} from 'slate-react';
import {fetchTagsService} from '../../../../domain/settings/subscriber-fields/service';
import useFetch from '../../../../hooks/useFetch';
import Button from '../../../Button';
import Input from '../../../forms/Input';
import Select from '../../../forms/Select';

export default ({insertLink, onRequestClose, isLinkActive, showAddTag}) => {
  const editor = useSlate();

  const {run: fetchTags} = useFetch(fetchTagsService, {
    throwError: true,
  });
  const [tags, setTags] = useState();
  const [tagOptions, setTagOptions] = useState();
  const [selectedTagId, setSelectedTagId] = useState();
  const [selectedTag, setSelectedTag] = useState();

  const [link, setLink] = useState(isLinkActive && isLinkActive[0]?.url);
  const [text, setText] = useState();

  useEffect(() => {
    const getTags = async () => {
      const resp = await fetchTags();
      if (isLinkActive && isLinkActive[0]?.tag) {
        const foundTag = resp.find((tag) => tag.id == isLinkActive[0].tag);

        setSelectedTag(foundTag.name);
      }
      const formattedOptions =
        resp.length > 0 && resp.map((tag) => ({label: tag.name, value: tag.name}));
      setTagOptions(formattedOptions);

      setTags(resp);
    };
    getTags();
  }, []);

  const {selection} = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);

  return (
    <div>
      <Input label="URL" value={link} onChange={(e) => setLink(e.target.value)} />
      {!isLinkActive && isCollapsed && (
        <Input label="Text" value={text} onChange={(e) => setText(e.target.value)} />
      )}
      {tags && tags.length > 0 && showAddTag && (
        <Select
          label="Add a tag"
          options={tagOptions}
          onChange={(value) => {
            const foundTag = tags.find((tag) => tag.name === value);
            setSelectedTag(foundTag);
            setSelectedTagId(foundTag.id);
          }}
          value={selectedTag}
          dense
        />
      )}
      <Button
        color="green"
        variant="filled"
        full
        onClick={() => {
          insertLink(editor, link, text, selectedTagId);
          onRequestClose();
        }}
      >
        {isLinkActive && isLinkActive[0]?.url ? 'Update' : 'Add'}
      </Button>
    </div>
  );
};
