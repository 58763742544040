import {ENDPOINTS} from './constants';
import {get, put, post} from '../../../utils/api';

export async function fetchProfileService(apiSettings) {
  return get(ENDPOINTS.fetch, null, apiSettings);
}

export async function updateProfileService(apiSettings, data) {
  return put(ENDPOINTS.update, data, apiSettings);
}

export async function cancelAccountService(apiSettings, data) {
  return post(ENDPOINTS.cancel, data, apiSettings);
}

export async function findPendingProfileEmailService(apiSettings) {
  return get(ENDPOINTS.findPendingProfileEmail, null, apiSettings);
}

export async function confirmProfileEmailService(apiSettings, data) {
  return post(ENDPOINTS.confirmEmail(data), null, apiSettings);
}

export async function initProfileEmailChangeService(apiSettings, data) {
  return put(ENDPOINTS.initProfileEmailChange(data), null, apiSettings);
}
