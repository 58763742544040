import {useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {useBoardingWizard} from '../../context/WizardContext';
import useRouter from '../../../../hooks/useRouter';
import ROUTES from '../../../../Router/routes';
import {STEPS} from '../../constants';
import BoardingWizard from './BoardingWizard';

function BoardingWizardContainer() {
  const {state, setActiveStep, destroyWizard} = useBoardingWizard();
  const {query, history} = useRouter();

  const {step} = query;
  const selectedPlanId = state.steps[STEPS.plans].data.id;
  const isAccessFromURL = step !== STEPS.plans && !selectedPlanId;

  useEffect(
    function () {
      if (!isAccessFromURL) {
        setActiveStep(step);
      }
    },
    [step, isAccessFromURL]
  );

  function handleModalClose() {
    history.replace(state.targetLocation || ROUTES.index);
    destroyWizard();
  }

  if (isAccessFromURL) {
    return <Redirect to={`${ROUTES.boarding.plans}`} />;
  }

  const isOpen = !!state.activeStep && step !== undefined;

  return <BoardingWizard isOpen={isOpen} onRequestClose={handleModalClose} />;
}

export default BoardingWizardContainer;
