import {useState, useMemo, useCallback, useEffect, useRef, createRef} from 'react';
import {createEditor, Transforms, Editor, Element as SlateElement, Range} from 'slate';
import {Slate, Editable, withReact, useSlate} from 'slate-react';
import {withHistory} from 'slate-history';
import pipe from 'lodash/fp/pipe';
import {isKeyHotkey} from 'is-hotkey';
import EmojiPicker from 'emoji-picker-react';
import isUrl from 'is-url';
import imageExtensions from 'image-extensions';
import {v4 as uuidv4} from 'uuid';
import omit from 'lodash.omit';
import Modal from '../Modal';

import withKeyCommands from './plugins/withKeyCommands';
import withLinks from './plugins/withLinks';

import {createParagraphNode} from './utils/paragraph';
import {deserialize, serialize} from './utils/rules';
import {defaultSubmitConfig} from '../../domain/settings/edit-form-text/constants';

import './styles.css';

import {
  Button,
  HoveringToolbar,
  Icon,
  Toolbar,
  LinkComponent,
  ColorButton,
  ImageComponent,
  SplitLayoutComponent,
} from './components';
import LinkInput from './components/LinkInput/LinkInput';
import ButtonDropdown from '../ButtonDropdown';
import ButtonDropdownItem from '../ButtonDropdown/ButtonDropdownItem';
import AddAsciiName from '../../domain/settings/edit-form-text/components/editor/utils/addAsciiName';
import withImages from './plugins/withImages';
import withTables from './plugins/withTables';
import LinkButton, {convertStyle} from './elements/LinkButton';
import {insertLinkButton} from '../../domain/settings/edit-form-text/components/editor/utils/linkButton';
import Input from '../forms/Input';
import FormButton from '../Button';
import useFetch from '../../hooks/useFetch';
import {deleteImage, uploadImage} from '../../domain/newsletters/service';
import {getFormData} from '../../utils/toFormData';
import {withHtml} from './plugins/withHtml';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right'];
export const FONT_SIZE_OPTIONS = [
  {text: '8px', value: '8px'},
  {text: '10px', value: '10px'},
  {text: '12px', value: '12px'},
  {text: '14px', value: '14px'},
  {text: '16px', value: '16px'},
  {text: '20px', value: '20px'},
  {text: '24px', value: '24px'},
  {text: '32px', value: '32px'},
  {text: '40px', value: '40px'},
  {text: '48px', value: '48px'},
];

const renderElement = (props) => {
  switch (props.element.type) {
    case 'linkButton':
      return <LinkButton allButons={props.buttons} {...props} />;
    case 'link':
      return <LinkComponent {...props} />;
    case 'split-layout':
      return <SplitLayoutComponent {...props} />;
    case 'image':
      return <ImageComponent {...props} />;
    default:
      return <Element {...props} />;
  }
};

export const isBlockActive = (editor, format, blockType = 'type') => {
  const [match] = Array.from(
    Editor.nodes(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
    })
  );

  return !!match;
};

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type) && !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });

  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      // eslint-disable-next-line no-nested-ternary
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }

  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = {type: format, children: []};
    Transforms.wrapNodes(editor, block);
  }
};

const addMarkData = (editor, data) => {
  Editor.addMark(editor, data.format, data.value);
};

const changeMarkData = (editor, event, format) => {
  if (event.preventDefault) event.preventDefault();
  const value = event.target?.value ? event.target.value : event.value;
  addMarkData(editor, {format, value});
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const activeMark = (editor, format) => {
  const defaultMarkData = {
    fontSize: '16px',
  };
  const marks = Editor.marks(editor);
  const defaultValue = defaultMarkData[format];
  return marks?.[format] ?? defaultValue;
};

export const Element = ({attributes, children, element}) => {
  // const style = element.align ? {...element.style, textAlign: element.align} : element.style;
  const style = {
    ...element.style,
    textAlign: element.align,
    backgroundColor: element.backgroundColor,
  };

  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case 'div':
      return (
        <div style={style} {...attributes}>
          {children}
        </div>
      );
    case 'span':
      return (
        <div {...attributes} style={[{fontSize: element.style.replace('font-size:', '')}]}>
          {children}
        </div>
      );
    case 'table':
      return (
        <table {...attributes} width={element.width} style={style}>
          {children}
        </table>
      );
    case 'tbody':
      return (
        <tbody {...attributes} width={element.width} style={style}>
          {children}
        </tbody>
      );
    case 'table-row':
      return (
        <tr {...attributes} style={style}>
          {children}
        </tr>
      );
    case 'table-cell':
      return (
        <td {...attributes} width={element.width} style={style}>
          {children}
        </td>
      );
    case 'quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'code':
      return (
        <pre>
          <code {...attributes}>{children}</code>
        </pre>
      );
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>;
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>;
    case 'heading-five':
      return <h5 {...attributes}>{children}</h5>;
    case 'heading-six':
      return <h6 {...attributes}>{children}</h6>;
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

export const Leaf = ({attributes, children, leaf}) => {
  let content = children;

  if (leaf.bold) {
    content = <strong>{content}</strong>;
  }

  if (leaf.code) {
    content = <code>{content}</code>;
  }

  if (leaf.italic) {
    content = <em>{content}</em>;
  }

  if (leaf.underline) {
    content = <u>{content}</u>;
  }

  if (leaf.fontSize) {
    content = <span style={{fontSize: leaf.fontSize}}>{content}</span>;
  }

  if (leaf.color) {
    content = <span style={{color: leaf.color}}>{content}</span>;
  }

  return <span {...attributes}>{content}</span>;
};

export const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
  return link;
};

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};

const wrapLink = (editor, url, text, tag) => {
  const {selection} = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{text: text || url}] : [],
    tag,
  };

  if (isLinkActive(editor) && isCollapsed) {
    Transforms.setNodes(editor, {url}, {at: isLinkActive(editor)[1]});
  } else if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, {split: true});
    Transforms.collapse(editor, {edge: 'end'});
  }
};

export const insertLink = (editor, url, text, tag) => {
  if (editor.selection) {
    wrapLink(editor, url, text, tag);
  }
};

export const insertImage = (editor, url, objectKey) => {
  const text = {text: ''};
  const image = {type: 'image', url, children: [text], objectKey};
  Transforms.insertNodes(editor, image);
};

export const isImageUrl = (url) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split('.').pop();
  return imageExtensions.includes(ext);
};

const uploadEditorImage = async (file, request, editor) => {
  try {
    const requestParams = {
      file,
      filename: file.name,
      mimeType: file.type,
    };

    const formData = getFormData(requestParams);

    const uploadResp = await request(formData);
    insertImage(editor, uploadResp.url, uploadResp.objectKey);
  } catch (error) {
    console.log('uploadResp error', error);
  }
};

const InsertImageButton = ({uploadImageRequest}) => {
  const fileInputRef = createRef();
  const editor = useSlate();

  return (
    <div>
      {/* <button type="button" onClick={() => fileInputRef.current.click()}>
        Select Image
      </button> */}
      <Button
        onMouseDown={(event) => {
          event.preventDefault();
          fileInputRef.current.click();
        }}
      >
        <Icon>image</Icon>
      </Button>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={(event) => uploadEditorImage(event.target.files[0], uploadImageRequest, editor)}
        hidden
      />
    </div>
  );
};

// eslint-disable-next-line no-unused-vars
const SplitLayotImageButton = () => {
  const editor = useSlate();
  const [table] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table',
  });
  return (
    <Button
      onMouseDown={(event) => {
        event.preventDefault();
        if (table) return;
        const splitLayout = [
          {
            type: 'table',
            width: '100%',
            style: {tableLayout: 'fixed'},
            children: [
              {
                type: 'table-row',
                children: [
                  {
                    type: 'table-cell',
                    style: {width: '45%', paddingRight: 30, verticalAlign: 'top'},

                    children: [
                      {
                        type: 'paragraph',
                        children: [
                          {
                            text: '1',
                            marks: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'table-cell',
                    style: {width: '45%', verticalAlign: 'top'},

                    children: [
                      {
                        type: 'paragraph',
                        children: [
                          {
                            text: '2',
                            marks: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            type: 'paragraph',
            children: [
              {
                text: '',
                marks: [],
              },
            ],
          },
        ];
        Transforms.insertNodes(editor, splitLayout);
      }}
    >
      <Icon>dashboard</Icon>
    </Button>
  );
};

export const BlockButton = ({format, icon}) => {
  const editor = useSlate();

  const [image] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'image',
  });

  return (
    <Button
      active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')}
      onMouseDown={(event) => {
        event.preventDefault();
        if (image && TEXT_ALIGN_TYPES.includes(format)) {
          switch (format) {
            case 'center':
              Transforms.setNodes(
                editor,
                {style: {marginLeft: 'auto', marginRight: 'auto'}},
                {at: image[1]}
              );
              break;
            case 'right':
              Transforms.setNodes(editor, {style: {marginLeft: 'auto'}}, {at: image[1]});
              break;
            default:
              Transforms.setNodes(editor, {style: {marginRight: 'auto'}}, {at: image[1]});
          }
        }
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

export const FormatButton = ({format, icon}) => {
  const editor = useSlate();
  return (
    <Button
      reversed
      active={isMarkActive(editor, format)}
      onClick={() => toggleMark(editor, format)}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

export const Dropdown = ({format, options}) => {
  const editor = useSlate();

  return (
    <Button>
      <ButtonDropdown
        buttonText={activeMark(editor, format)}
        popoverStyle={{maxHeight: 200, overflow: 'scroll'}}
        style={{height: 24, fontSize: 14}}
      >
        {options.map((option) => (
          <ButtonDropdownItem onSelect={() => changeMarkData(editor, option, format)}>
            {option.text}
          </ButtonDropdownItem>
        ))}
      </ButtonDropdown>
    </Button>
  );
};

export const MarkButton = ({format, icon}) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

export const AddLinkButton = ({setLinkModalOpen}) => {
  const editor = useSlate();
  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={(event) => {
        event.preventDefault();
        setLinkModalOpen(true);
      }}
    >
      <Icon>link</Icon>
    </Button>
  );
};

export const RemoveLinkButton = () => {
  const editor = useSlate();
  if (!isLinkActive(editor)) return;

  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={() => {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        }
      }}
    >
      <Icon>link_off</Icon>
    </Button>
  );
};

export const AddEmojiButton = ({isEmojiModalOpen, setEmojiModalOpen, emojiRef}) => {
  const editor = useSlate();

  return (
    <div ref={emojiRef} onClick={(e) => e.stopPropagation()}>
      <Button
        onMouseDown={(event) => {
          event.preventDefault();
          setEmojiModalOpen(!isEmojiModalOpen);
        }}
      >
        <Icon>add_reaction</Icon>
      </Button>
      {isEmojiModalOpen && (
        <div style={{position: 'absolute', bottom: 260, zIndex: 3}}>
          <EmojiPicker
            height={300}
            previewConfig={{showPreview: false}}
            size="16"
            onEmojiClick={(emoji) => {
              Transforms.insertNodes(editor, {text: emoji.emoji});
              setEmojiModalOpen(false);
            }}
            lazyLoadEmojis
          />
        </div>
      )}
    </div>
  );
};

export const ColorTextButton = () => {
  const editor = useSlate();
  const value = activeMark(editor, 'color');

  return (
    <ColorButton
      active={activeMark(editor, 'color')}
      onChangeColor={(color) => changeMarkData(editor, color, 'color')}
      value={value}
    >
      <Icon>format_color_text</Icon>
    </ColorButton>
  );
};

export const BackgroundColofButton = () => {
  const editor = useSlate();
  return (
    <ColorButton
      active={activeMark(editor, 'backgroundColor')}
      onChangeColor={(e) => {
        const [table] = Editor.nodes(editor, {
          match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table',
        });

        changeMarkData(editor, e.target.value, 'backgroundColor');
        // eslint-disable-next-line no-unused-expressions
        table
          ? Transforms.setNodes(editor, {backgroundColor: e.target.value}, {at: table[1]})
          : Transforms.setNodes(editor, {backgroundColor: e.target.value});
      }}
    >
      <Icon>format_color_fill</Icon>
    </ColorButton>
  );
};

const AddButton = ({setButtons, setActiveButton, buttonContext}) => {
  const editor = useSlate();

  const [linkButton] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'linkButton',
  });

  if (linkButton) return;

  const buttonId = uuidv4();

  return (
    <Button
      onMouseDown={() => {
        insertLinkButton(editor, ' ', buttonId);
        Transforms.insertText(editor, '\n');
        setButtons((prevButtons) => [
          ...prevButtons,
          {buttonId, style: defaultSubmitConfig.buttonStyle},
        ]);
        setActiveButton(buttonId);
        buttonContext.setButtonConfig(defaultSubmitConfig.buttonStyle);

        buttonContext?.setToolbarOpen(true);
      }}
    >
      <Icon>smart_button</Icon>
    </Button>
  );
};

const insertAsciiName = (editor, asciiValue) => {
  const asciiName = {
    text: ` {{${asciiValue}}} `,
  };

  Transforms.insertNodes(editor, asciiName);
};

export const renderToolbarButtons = (
  setLinkModalOpen,
  isEmojiModalOpen,
  setEmojiModalOpen,
  emojiRef,
  buttons,
  setButtons,
  setActiveButton,
  buttonContext,
  setImageModalOpen,
  uploadImageRequest
) => {
  return (
    <>
      <Dropdown format="fontSize" options={FONT_SIZE_OPTIONS} />
      <ColorTextButton />
      <BackgroundColofButton />
      <MarkButton format="bold" icon="format_bold" />
      <MarkButton format="italic" icon="format_italic" />
      <MarkButton format="underline" icon="format_underlined" />
      <MarkButton format="code" icon="code" />
      <BlockButton format="block-quote" icon="format_quote" />
      <BlockButton format="numbered-list" icon="format_list_numbered" />
      <BlockButton format="bulleted-list" icon="format_list_bulleted" />
      <BlockButton format="left" icon="format_align_left" />
      <BlockButton format="center" icon="format_align_center" />
      <BlockButton format="right" icon="format_align_right" />
      <InsertImageButton
        setImageModalOpen={setImageModalOpen}
        uploadImageRequest={uploadImageRequest}
      />
      {/* <SplitLayotImageButton /> */}
      <AddLinkButton setLinkModalOpen={setLinkModalOpen} />
      <RemoveLinkButton />
      <AddEmojiButton
        isEmojiModalOpen={isEmojiModalOpen}
        setEmojiModalOpen={setEmojiModalOpen}
        emojiRef={emojiRef}
      />
      {buttonContext && (
        <AddButton
          buttons={buttons}
          setButtons={setButtons}
          setActiveButton={setActiveButton}
          buttonContext={buttonContext}
        />
      )}
    </>
  );
};

export const renderHoveringToolbar = () => {
  return (
    <>
      <HoveringToolbar>
        <FormatButton format="bold" icon="format_bold" />
        <FormatButton format="italic" icon="format_italic" />
        <FormatButton format="underline" icon="format_underlined" />
      </HoveringToolbar>
    </>
  );
};

const createEditorWithPlugins = pipe(
  withReact,
  withHistory,
  withLinks,
  withImages,
  withKeyCommands,
  withTables,
  // withSimpleCopyPaste,
  withHtml
);

const StaticLinkButton = ({href, children, id, nodeStyle, buttons = []}) => {
  const foundButtonStyle = buttons.find((button) => button.buttonId === id)?.style;
  const convertedStyle = convertStyle(nodeStyle, href);
  const buttonStyle = foundButtonStyle || convertedStyle || defaultSubmitConfig.buttonStyle;
  const cleanedButtonStyle = omit(buttonStyle, 'url');

  return (
    <table
      width="100%"
      data-href={buttonStyle.url || href}
      data-type="linkButton"
      data-id={id}
      style={{
        ...cleanedButtonStyle,
        padding: 10,
        display: 'block',
        textDecoration: 'none',
      }}
    >
      <tr width="100%">
        <td width="100%" align={cleanedButtonStyle.textAlign}>
          <a
            href={href || href}
            style={{
              ...cleanedButtonStyle,
              padding: 10,
              display: 'block',
              textDecoration: 'none',
              textAlign: 'center',
            }}
            // data-id={id}
            // data-type="linkButton"
          >
            {children}
          </a>
        </td>
      </tr>
    </table>
  );
};

const SlateEditor = ({content, onContentChange, placeholder, ...props}) => {
  const renderLeaf = useCallback(
    ({attributes, children, leaf}) => (
      <Leaf attributes={attributes} leaf={leaf}>
        {children}
      </Leaf>
    ),
    []
  );

  const emojiRef = useRef();

  const hasButtonContext = props.buttonContext;

  const [isLinkModalOpen, setLinkModalOpen] = useState(false);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [isEmojiModalOpen, setEmojiModalOpen] = useState(false);
  const [buttons, setButtons] = useState([]);
  const [activeButton, setActiveButton] = useState();
  const {run: uploadImageRequest} = useFetch(uploadImage);
  const {run: deleteImageRequest} = useFetch(deleteImage);

  const handleClickOutside = (e) => {
    if (emojiRef.current && !emojiRef.current.contains(e.target)) {
      setEmojiModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const customTypes = {
    LinkButton: (linkProps) => <StaticLinkButton {...linkProps} buttons={buttons} />,
  };

  const contentDocument = new DOMParser().parseFromString(content, 'text/html');
  const isHtml = Array.from(contentDocument.body.childNodes).some((node) => node.nodeType === 1);
  const deserializedContent = deserialize(contentDocument.body);

  const initialValue =
    content && isHtml
      ? deserializedContent
      : [
          createParagraphNode([
            {
              text: content || '',
            },
          ]),
        ];

  const editor = useMemo(() => createEditorWithPlugins(createEditor()), []);

  const [value, setValue] = useState(initialValue);

  const onChange = (v) => {
    const serialized = serialize({children: v}, customTypes);

    onContentChange(serialized);
    setValue(v);
  };

  useEffect(() => {
    if (!content) return;

    const serialized = serialize({children: value}, customTypes);

    if (content !== serialized) {
      const contentDoc = new DOMParser().parseFromString(content, 'text/html');

      const deserialized = deserialize(contentDoc.body);
      setValue(deserialized);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  useEffect(() => {
    Editor.normalize(editor, {force: true});
  }, [editor]);

  // const slateValue = useMemo(() => {
  //   // Slate throws an error if the value on the initial render is invalid
  //   // so we directly set the value on the editor in order
  //   // to be able to trigger normalization on the initial value before rendering
  //   editor.children = externalValue;
  //   Editor.normalize(editor, { force: true });
  //   // We return the normalized internal value so that the rendering can take over from here
  //   return editor.children;
  // }, [editor, externalValue]);

  useEffect(() => {
    if (hasButtonContext && activeButton && buttons.length) {
      const clonedButtons = [...buttons];
      const activeButtonIndex = clonedButtons.findIndex(
        (button) => button.buttonId === activeButton
      );

      const stringedButtonStyle = JSON.stringify(clonedButtons[activeButtonIndex].style);
      const stringedActiveButtonConfig = JSON.stringify(props.buttonContext.activeButtonConfig);
      if (activeButtonIndex > -1 && stringedButtonStyle !== stringedActiveButtonConfig) {
        // if (
        //   stringedButtonStyle ==
        //   stringedActiveButtonConfig
        // )
        //   return;

        clonedButtons[activeButtonIndex].style = props.buttonContext.activeButtonConfig;
        setButtons(clonedButtons);
        // onChange(value);
        // Editor.normalize(editor, {force: true});
      }
    }
  }, [props.buttonContext?.activeButtonConfig]);

  const onKeyDown = (event) => {
    const {selection} = editor;

    if (selection && Range.isCollapsed(selection)) {
      const {nativeEvent} = event;
      if (isKeyHotkey('left', nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, {unit: 'offset', reverse: true});
        return;
      }
      if (isKeyHotkey('right', nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, {unit: 'offset'});
        return;
      }
      const [match] = Editor.nodes(editor, {
        match: (n) => n.type === 'link',
      });

      const [linkButton] = Editor.nodes(editor, {
        match: (n) => n.type === 'linkButton',
      });

      const [paragraph] = Editor.nodes(editor, {
        match: (n) => n.type === 'paragraph',
      });

      if (event.key === 'Enter' && linkButton) {
        event.preventDefault();
      }

      if (event.key === 'Enter' && !event.shiftKey && match) {
        event.preventDefault();
        const newLine = {
          type: 'paragraph',
          children: [
            {
              text: '',
              marks: [],
            },
          ],
        };
        Transforms.insertNodes(editor, newLine);
        return;
      }

      const [table] = Editor.nodes(editor, {
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table',
      });

      if (table && event.key === 'Enter' && event.shiftKey) {
        event.preventDefault();

        Transforms.insertText(editor, '\n');
        return;
      }

      if (event.key === 'Enter' && paragraph) {
        event.preventDefault();
        if (event.shiftKey) {
          Transforms.insertText(editor, '\n');
        } else {
          const newLine = {
            type: 'paragraph',
            children: [
              {
                text: '',
                marks: [],
              },
            ],
            backgroundColor: '#ffffff',
          };
          Transforms.insertNodes(editor, newLine);
        }
      }
    }
  };

  editor.children = value;

  return (
    <div className="editor-wrapper">
      <Slate editor={editor} value={value} onChange={onChange}>
        <>
          <Modal
            isOpen={isLinkModalOpen}
            onRequestClose={() => setLinkModalOpen(false)}
            title="Add Link"
          >
            <LinkInput
              insertLink={insertLink}
              isLinkActive={isLinkActive(editor)}
              showAddTag={props.canAddTagsToLinks}
            />
          </Modal>
          <Modal
            isOpen={isImageModalOpen}
            onRequestClose={() => setImageModalOpen(false)}
            title="Enter the URL of the image"
          >
            <Input label="URL" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
            <FormButton
              color="green"
              variant="filled"
              full
              onClick={() => {
                if (imageUrl && !isImageUrl(imageUrl)) {
                  alert('URL is not an image');
                  return;
                }
                // eslint-disable-next-line no-unused-expressions
                imageUrl && insertImage(editor, imageUrl);
                setImageModalOpen(false);
                setImageUrl('');
              }}
            >
              Add Image
            </FormButton>
          </Modal>
          <Toolbar>
            {renderToolbarButtons(
              setLinkModalOpen,
              isEmojiModalOpen,
              setEmojiModalOpen,
              emojiRef,
              buttons,
              setButtons,
              setActiveButton,
              props.buttonContext,
              setImageModalOpen,
              uploadImageRequest
            )}
            {props.showAsciiInput && (
              <Button>
                <AddAsciiName
                  onChange={(asciiName) => {
                    insertAsciiName(editor, asciiName);
                  }}
                />
              </Button>
            )}
          </Toolbar>
          {renderHoveringToolbar()}
        </>
        <Editable
          autoFocus
          renderElement={(renderProps) =>
            renderElement({
              ...renderProps,
              ...props,
              buttons,
              setButtons,
              activeButton,
              setActiveButton,
              deleteImageRequest,
            })
          }
          renderLeaf={renderLeaf}
          placeholder={placeholder || 'Start typing...'}
          onKeyDown={onKeyDown}
        />
      </Slate>
    </div>
  );
};

export default SlateEditor;
