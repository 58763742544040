import {useState} from 'react';
import Button from '../../../components/Button';
import SwitchActive from '../../../img/switch-green-active.png';
import SwitchInactive from '../../../img/switch-green-inactive.png';
import styles from './TrackingOptions.module.scss';

const TrackingOptions = ({
  trackingOptions,
  setTrackingOptionsOpen,
  handleTrackingOptionsUpdate,
}) => {
  const [options, setOptions] = useState(trackingOptions);
  return (
    <div>
      <div className={styles.switchContainer}>
        <div
          className={styles.switchButton}
          onClick={() => setOptions({...options, openTracking: !options.openTracking})}
        >
          <img
            src={options.openTracking ? SwitchActive : SwitchInactive}
            alt="Open tracking switch"
          />
        </div>
        <div className={styles.switchTextContainer}>
          <div className={styles.switchTitle}>Open Tracking</div>
          <div className={styles.switchDescription}>
            If Open Tracking is enabled, you can see how many subscribers opened a newsletter
            campaign and how many times the campaign was opened in total.
          </div>
        </div>
      </div>
      <div className={styles.switchContainer}>
        <div
          className={styles.switchButton}
          onClick={() => setOptions({...options, clickTracking: !options.clickTracking})}
        >
          <img
            src={options.clickTracking ? SwitchActive : SwitchInactive}
            alt="Click tracking switch"
          />
        </div>
        <div className={styles.switchTextContainer}>
          <div className={styles.switchTitle}>Click Tracking</div>
          <div className={styles.switchDescription}>
            If Click Tracking is enabled, you can see how many subscribers clicked on a link in a
            newsletter campaign, and how many times a link was clicked in total.
          </div>
        </div>
      </div>
      <Button
        color="green"
        variant="filled"
        full
        onClick={() => {
          handleTrackingOptionsUpdate(options);
          setTrackingOptionsOpen(false);
        }}
      >
        Save
      </Button>
      <div style={{marginTop: 10}}>
        <Button color="grey" variant="outlined" full onClick={() => setTrackingOptionsOpen(false)}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default TrackingOptions;
