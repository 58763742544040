import {Helmet} from 'react-helmet';
import NewsletterContainer from './NewsletterContainer';

function NewsletterPage() {
  return (
    <>
      <Helmet title="Newsletter Campaign" />
      <NewsletterContainer />
    </>
  );
}

export default NewsletterPage;
