import {useReducer, createContext, useCallback, useMemo} from 'react';
import set from 'lodash.set';
import {useImmerReducer} from 'use-immer';

import {defaultSubmitConfig} from '../settings/edit-form-text/constants';

const NewsletterContext = createContext();

const initialState = {
  activeButtonConfig: defaultSubmitConfig.buttonStyle,
  showToolbar: false,
};

export const NewsletterProvider = ({children}) => {
  const [state, dispatch] = useImmerReducer((draft, action) => {
    if (action.type == 'SET_BUTTON_CONFIG_PARAM') {
      set(draft, action.payload.path, action.payload.value);
    }

    if (action.type == 'TOGGLE_TOOLBAR') {
      draft.showToolbar = action.payload;
    }

    if (action.type == 'SET_BUTTON_CONFIG') {
      draft.activeButtonConfig = action.payload;
    }
  }, initialState);

  const setToolbarOpen = (toolbarState) => {
    return dispatch({type: 'TOGGLE_TOOLBAR', payload: toolbarState});
  };

  const setButtonConfig = (buttonConfig) => {
    return dispatch({type: 'SET_BUTTON_CONFIG', payload: buttonConfig});
  };

  const providerValue = {
    ...state,
    dispatch,
    setToolbarOpen,
    setButtonConfig,
  };

  return <NewsletterContext.Provider value={providerValue}>{children}</NewsletterContext.Provider>;
};

export default NewsletterContext;
