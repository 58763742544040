import React from 'react';

import uniq from 'lodash.uniq';
import ToolbarSection from './ToolbarSection';
import SelectField from './SelectField';
import styles from './Toolbar.module.scss';
import {SubscriptionFormBuilderContext} from '../../context';
import Button from '../../../../components/Button';
import TextField from './TextField';
import {addField} from '../../context/actions';
import CheckboxField from './CheckboxField';

const AddNewFieldStyles = () => {
  const {
    formState,
    subscriberFields,
    dispatch,
    setSelectedElement,
    setShowAddNewFieldModal,
  } = React.useContext(SubscriptionFormBuilderContext);
  const customFieldsOptions = React.useMemo(() => {
    const existedFieldsNames = formState.inputFields.map(({name}) => name);

    return subscriberFields
      .filter(({asciiName}) => {
        return !existedFieldsNames.includes(asciiName);
      })
      .map(({name, asciiName, dataType}) => ({label: name, value: asciiName, dataType}));
  }, [formState, subscriberFields]);

  const [newFieldName, setNewFieldName] = React.useState('');
  const [newFieldLabel, setNewFieldLabel] = React.useState('');
  const [placeholder, setPlaceholder] = React.useState('');
  const [isRequired, setRequired] = React.useState(false);
  const [errors, setErrors] = React.useState(() => []);

  const handleChangeName = React.useCallback(
    (value) => {
      const label = customFieldsOptions.find((i) => i.value === value)?.label || value;
      setNewFieldName(value);
      setNewFieldLabel(label);
    },
    [setNewFieldName, setNewFieldLabel, customFieldsOptions]
  );

  const handleChangeLabel = React.useCallback(
    (e) => {
      setNewFieldLabel(e.target.value);
    },
    [setNewFieldLabel]
  );

  const handleChangePlaceholder = React.useCallback(
    (e) => {
      setPlaceholder(e.target.value);
    },
    [setPlaceholder]
  );

  const handleChangeRequired = React.useCallback(
    (e) => {
      setRequired(e.target.checked);
    },
    [setRequired]
  );

  const handleConfirm = React.useCallback(() => {
    if (!newFieldName) {
      setErrors((prevErrors) => uniq([...prevErrors, 'name']));
    }

    if (!newFieldLabel && !placeholder) {
      setErrors((prevErrors) => uniq([...prevErrors, 'label', 'placeholder']));
    }

    if ((!newFieldLabel && !placeholder) || !newFieldName) {
      return;
    }

    const dataType = customFieldsOptions.find((i) => i.value === newFieldName)?.dataType;

    dispatch(addField(newFieldName, newFieldLabel, dataType, placeholder, isRequired));

    setSelectedElement(`field.${newFieldName}`);
    setShowAddNewFieldModal(false);
  }, [
    dispatch,
    setSelectedElement,
    newFieldName,
    newFieldLabel,
    setErrors,
    placeholder,
    isRequired,
  ]);

  React.useEffect(() => {
    if (newFieldName) {
      setErrors([]);
    }
  }, [newFieldName, setErrors]);

  return (
    <>
      <SelectField
        label="Select field"
        options={customFieldsOptions}
        error={errors.includes('name') && 'Field type is not selected'}
        onChange={handleChangeName}
        value={newFieldName}
      />
      <TextField
        error={errors.includes('label') && 'Either label or placeholder should be filled'}
        label="Label"
        onChange={handleChangeLabel}
        value={newFieldLabel}
      />
      <TextField
        error={errors.includes('placeholder') && 'Either label or placeholder should be filled'}
        onChange={handleChangePlaceholder}
        value={placeholder}
        label="Placeholder"
      />
      <CheckboxField
        id="add_new_field_required"
        onChange={handleChangeRequired}
        checked={isRequired}
        label="Required"
      />
      <Button color="green" variant="filled" size="small" onClick={handleConfirm} full>
        Confirm
      </Button>
      <div style={{marginTop: 10}}>
        <Button
          color="grey"
          variant="outlined"
          size="small"
          onClick={() => setShowAddNewFieldModal(false)}
          full
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default AddNewFieldStyles;
