import {useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import {useDataTableContext} from '../context/TableContext';
import useDataTableFetcher from '../useDataTableFetcher';
import styles from './Pagination.module.scss';
import Listbox from '../../Listbox';
import ListboxOption from '../../Listbox/ListboxOption';
import Icon from '../../Icon';
import CustomFilter from '../CustomFilter';

function Pagination({customFilter}) {
  const {
    state: {meta, formatted, filters},
    goToNextPage,
    changePerPageSize,
    goToPrevPage,
    forcePageChange,
  } = useDataTableContext();
  const [perPageValue, setPerPageValue] = useState(meta.perPage);
  const [tableFetcher] = useDataTableFetcher();

  async function handleLimitChange(value) {
    const perPage = Number(value);
    setPerPageValue(perPage);
    const response = await tableFetcher({
      limit: perPage,
      page: 1,
    });
    changePerPageSize({perPage, response});
  }

  async function handlePrevClick() {
    const response = await tableFetcher({
      page: meta.prev.page,
      limit: meta.prev.limit,
    });
    goToPrevPage({response});
  }

  async function handleNextClick() {
    const response = await tableFetcher({
      page: meta.next.page,
      limit: meta.next.limit,
    });
    goToNextPage({response});
  }

  async function handleFirstPageClick() {
    const response = await tableFetcher({
      page: meta.first.page,
      limit: meta.first.limit,
    });
    forcePageChange({page: meta.first.page, response});
  }

  async function handleLastPageClick() {
    const response = await tableFetcher({
      page: meta.last.page,
      limit: meta.last.limit,
    });
    forcePageChange({page: meta.last.page, response});
  }

  function renderCustomFilter() {
    if (!customFilter || !filters?.[customFilter.targetFilter]) {
      return null;
    }

    // @TODO extend to support multiple filters in the future
    return (
      <CustomFilter value={filters[customFilter.targetFilter]} className={styles.dropdown}>
        {customFilter.nodes}
      </CustomFilter>
    );
  }

  return (
    <div
      className={cx(styles.pagination, {
        [styles.first]: meta.page === 1,
        [styles.last]: meta.page === meta.totalPages,
      })}
    >
      <span>Show</span>
      {renderCustomFilter()}
      {!!meta.totalPages && (
        <>
          <Listbox
            value={String(perPageValue)}
            onChange={handleLimitChange}
            className={styles.dropdown}
          >
            <ListboxOption value="20">20</ListboxOption>
            <ListboxOption value="50">50</ListboxOption>
            <ListboxOption value="100">100</ListboxOption>
          </Listbox>
          <span>entries of {formatted.totalCount}</span>
          <div className={styles.paginator}>
            <Icon
              name="first-page"
              className={cx(styles.arr, styles.firstPage)}
              onClick={handleFirstPageClick}
            />
            <Icon
              name="arrow-left"
              className={cx(styles.arr, styles.prev)}
              onClick={handlePrevClick}
            />
            <span>{meta.page}</span>
            <Icon
              name="arrow-right"
              className={cx(styles.arr, styles.next)}
              onClick={handleNextClick}
            />
            <Icon
              name="last-page"
              className={cx(styles.arr, styles.lastPage)}
              onClick={handleLastPageClick}
            />
          </div>
        </>
      )}
    </div>
  );
}

Pagination.propTypes = {
  customFilter: PropTypes.shape({
    targetFilter: PropTypes.string,
    nodes: PropTypes.node,
  }),
};

export default Pagination;
