import ROUTES from '../../Router/routes';
import AutomationIcon from '../../components/icons/AutomationIcon';

export const MENU = [
  {
    label: 'Subscribers',
    icon: 'users',
    pathname: ROUTES.index,
    active: new RegExp(`^${ROUTES.subscribers.index}/.*`),
  },
  {
    label: 'Campaigns',
    icon: 'envelope',
    pathname: ROUTES.newsletters.index,
  },
  {
    label: 'Signup-Forms',
    icon: 'file-list',
    pathname: ROUTES.signupForms.index,
    active: new RegExp(`^${ROUTES.signupForms.index}/.*`),
  },
  {
    label: 'Automations',
    pathname: ROUTES.automation.index,
    SvgIcon: AutomationIcon,
  },
  {
    label: 'Settings',
    icon: 'gear',
    pathname: ROUTES.settings.index,
    children: [
      {
        label: 'Segments',
        pathname: ROUTES.settings.segments.index,
        active: new RegExp(`^${ROUTES.settings.segments.index}/.*`),
      },
      {label: 'Subscriber Fields', pathname: ROUTES.settings.subscriberFields},
      // {
      //   label: 'Newsletter Templates',
      //   pathname: ROUTES.settings.newsletterTemplates,
      // },
      {label: 'Transactional Emails', pathname: ROUTES.settings.transactionalEmails.index},
      {label: 'Marketing Permissions', pathname: ROUTES.settings.marketingPermissions},
      // {label: 'Subscription Flow', pathname: ROUTES.settings.editFormText},
      {label: 'User Profile', pathname: ROUTES.settings.userProfile},
      {label: 'Email Address', pathname: ROUTES.settings.emailSender},
      {
        label: 'Postal Address',
        pathname: ROUTES.settings.postalAddress,
        requirePostalAddress: true,
      },
      {
        label: 'My Subscription',
        pathname: ROUTES.settings.subscription,
        active: new RegExp(`^${ROUTES.settings.subscription}/.*`),
      },
      {label: 'Invoices', pathname: ROUTES.settings.invoices},
      {label: 'Billing', pathname: ROUTES.settings.billing, requireBilling: true},
      {label: 'Affiliate', pathname: ROUTES.settings.affiliate},
    ],
  },
];
