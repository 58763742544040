import PropTypes from 'prop-types';

export const newslettersPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    subject: PropTypes.string,
    status: PropTypes.oneOf(['DRAFT', 'SENT']),
    report: PropTypes.shape({
      deliveredCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      openCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      clickCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      unsubscribeCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  })
);

export const newsletterReportPropType = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    subject: PropTypes.string,
    publishDate: PropTypes.string,
    recipientCount: PropTypes.string,
    deliveryReport: PropTypes.shape({
      deliveryRate: PropTypes.string,
      deliveryCount: PropTypes.string,
      delayCount: PropTypes.string,
      rejectionCount: PropTypes.string,
    }),
    openReport: PropTypes.shape({
      totalTopOpenRate: PropTypes.string,
      uniqueTopOpenRate: PropTypes.string,
      uniqueTopOpenCount: PropTypes.string,
      totalTopOpenCount: PropTypes.string,
      uniqueBottomOpenCount: PropTypes.string,
      totalBottomOpenCount: PropTypes.string,
    }),
    clickReport: PropTypes.shape({
      clickRate: PropTypes.string,
      clickToOpenRate: PropTypes.string,
      uniqueClickCount: PropTypes.string,
      totalClickCount: PropTypes.string,
    }),
    unsubscribeReport: PropTypes.shape({
      unsubscribeRate: PropTypes.string,
      unsubscribeCount: PropTypes.string,
    }),
    spamReport: PropTypes.shape({
      spamComplaintRate: PropTypes.string,
      spamComplaintCount: PropTypes.string,
    }),
    bounceReport: PropTypes.shape({
      undeterminedBounceRate: PropTypes.string,
      outOfBandBounceRate: PropTypes.string,
      softBounceRate: PropTypes.string,
      blockBounceRate: PropTypes.string,
      adminBounceRate: PropTypes.string,
      hardBounceRate: PropTypes.string,
      totalBounceRate: PropTypes.string,
      undeterminedBounceCount: PropTypes.string,
      outOfBandBounceCount: PropTypes.string,
      softBounceCount: PropTypes.string,
      blockBounceCount: PropTypes.string,
      adminBounceCount: PropTypes.string,
      hardBounceCount: PropTypes.string,
      totalBounceCount: PropTypes.string,
    }),
  }),
};

export const newsletterContentPropType = PropTypes.shape({
  id: PropTypes.string,
  content: PropTypes.string,
  recipientCount: PropTypes.string,
  senderFrom: PropTypes.string,
  sentTimestamp: PropTypes.string,
  status: PropTypes.string,
  subject: PropTypes.string,
});

export const overviewNewslettersPropType = PropTypes.shape({
  formattedHeader: PropTypes.shape({
    totalCount: PropTypes.string,
    totalPages: PropTypes.string,
  }),
  rows: newslettersPropType,
});
