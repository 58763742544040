import {useMemo} from 'react';
import MD5 from 'md5.js';
import styles from './SubscriberHeader.module.scss';

function SubscriberHeader({data}) {
  const subscriberEmail = useMemo(
    () => data?.values?.find(({asciiName}) => asciiName === 'email')?.value,
    [data]
  );

  const imageSrc = useMemo(() => {
    if (!data || !subscriberEmail) {
      return '';
    }
    const emailPrepared = subscriberEmail.trim().toLowerCase();

    if (!emailPrepared) {
      return '';
    }

    const emailMd5 = new MD5(emailPrepared).update(emailPrepared).digest('hex');
    return `https://www.gravatar.com/avatar/${emailMd5}`;
  }, [data, subscriberEmail]);

  if (!data) {
    return null;
  }

  return (
    <div className={styles.header}>
      {imageSrc && <img src={imageSrc} alt="Subscriber's Gravatar" className={styles.headerImg} />}
      <div className={styles.headerInfo}>
        <h1>{subscriberEmail}</h1>
        <p>
          <b>Subscription Date:</b> {data.registeredAt}
        </p>
      </div>
    </div>
  );
}

export default SubscriberHeader;
