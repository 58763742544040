import {useState} from 'react';
import {
  addSubscriberService,
  deleteSubscriberService,
  editSubscriberService,
  exportSubscribersService,
  fetchSubscribersService,
} from '../service';
import DataTable from '../../../components/DataTable';
import TableHeading from '../../../components/DataTable/TableHeading';

import ActionForm from '../ActionForm';
import useFetch from '../../../hooks/useFetch';
import useUpdateFreePlanInfo from '../../../hooks/useUpdateFreePlanInfo';
import DropdownButton from '../../../components/ButtonDropdown';
import Button from '../../../components/Button';
import ButtonDropdownItem from '../../../components/ButtonDropdown/ButtonDropdownItem';
import ListboxOption from '../../../components/Listbox/ListboxOption';
import ImportSubscribersWizard from '../../import-subscribers/ImportSubscribersWizard';
import Modal from '../../../components/Modal';
import ExportModalSuccess from './ExportModalSuccess';
import {useImportSubscribersContext} from '../../import-subscribers/useImportSubscribersContext';
import {handleErrorAlert} from '../../../utils/handleErrorAlert';
import useAlert from '../../alerts/useAlert';
import BulkDeleteSuccess from './BulkDeleteSuccess';
import {useFreePlan} from '../../free-plan-info/context/FreePlanContext';
import useAuditStatus from '../../audit-status/useAuditStatus';
import AuditSubscribersBulkModal from '../../audit-status/subscribers-bulk-modal/AuditSubscribersBulkModal';
import useRouter from '../../../hooks/useRouter';
import ROUTES from '../../../Router/routes';
import styles from './SubscribersTableContainer.module.scss';

function SubscribersTableContainer() {
  const {history} = useRouter();
  const {showAlert} = useAlert();
  const [exportConfirmModalOpen, setExportConfirmModalOpen] = useState(false);
  const {run: fetchSubscribersRequest} = useFetch(fetchSubscribersService);
  const {run: bulkDeleteSubscribersRequest} = useFetch(deleteSubscriberService, {throwError: true});
  const {run: deleteSubscriberRequest} = useFetch(deleteSubscriberService);
  const {run: addSubscriberRequest} = useFetch(addSubscriberService, {throwError: true});
  const {run: editSubscriberRequest} = useFetch(editSubscriberService, {throwError: true});
  const {openWizard} = useImportSubscribersContext();
  const {updateFreePlanInfo} = useUpdateFreePlanInfo();
  const {state} = useFreePlan();
  const {planLimitReached} = state;
  const {
    isAuditModalOpen,
    toggleAuditModalOpen,
    isAuditOk,
    auditStatus,
    recheckAuditStatusOk,
  } = useAuditStatus();

  const {run: exportSubscribersRequest} = useFetch(exportSubscribersService, {throwError: true});

  const resolveBulkActions = (resolve, isAuditOkRes) => {
    if (!isAuditOkRes) {
      toggleAuditModalOpen();
    }
    resolve(isAuditOkRes);
  };

  const isBulkActionAllowed = () => {
    return new Promise((resolve) => {
      if (auditStatus) {
        resolveBulkActions(resolve, isAuditOk(auditStatus));
      } else {
        recheckAuditStatusOk().then((isAuditOkRes) => {
          resolveBulkActions(resolve, isAuditOkRes);
        });
      }
    });
  };

  function handleAddNewSubscriber() {
    history.push(ROUTES.subscribers.create);
  }

  async function handleOpenWizard() {
    if (await isBulkActionAllowed()) {
      openWizard();
    }
  }

  async function handleBulkDelete(data) {
    if (await isBulkActionAllowed()) {
      await bulkDeleteSubscribersRequest(data);
      await updateFreePlanInfo();
    }
  }

  async function handleSingleDelete(data) {
    if (await isBulkActionAllowed()) {
      await deleteSubscriberRequest(data);
      await updateFreePlanInfo();
    }
  }

  async function handleFetch(params) {
    await updateFreePlanInfo();
    return fetchSubscribersRequest(params);
  }

  async function handleAddSubscriber(values) {
    await addSubscriberRequest(values);
    await updateFreePlanInfo();
  }

  async function handleEditSubscriber(values) {
    return editSubscriberRequest(values);
  }

  function closeExportConfirmModal() {
    setExportConfirmModalOpen(false);
  }

  async function handleExport(data) {
    try {
      await exportSubscribersRequest(data);
      setExportConfirmModalOpen(true);
    } catch (error) {
      handleErrorAlert(error, showAlert);
    }
  }

  const getEditPageRoute = (rowData) => {
    return `${ROUTES.subscribers.index}/${rowData.id}`;
  };

  const handleOpenEditPage = (rowData) => (event) => {
    event.preventDefault();
    history.push(getEditPageRoute(rowData));
  };

  const TdEditLink = ({children, rowData, type, ...props}) => {
    const Component = type === 'a' ? 'a' : 'span';
    return (
      <Component
        onClick={handleOpenEditPage(rowData)}
        className={styles.tdEditLink}
        role="button"
        {...props}
      >
        {children}
      </Component>
    );
  };

  return (
    <>
      <DataTable
        fetcher={handleFetch}
        onBulkDelete={handleBulkDelete}
        onDelete={handleSingleDelete}
        isBulkDeleteAllowed={isBulkActionAllowed}
        onExport={handleExport}
        bulkDeleteSuccessContent={isAuditOk(auditStatus) && <BulkDeleteSuccess />}
        searchPlaceholder="Search for subscribers"
        createButtonTrigger={() => (
          <DropdownButton
            buttonText="Add a New Subscriber"
            as={Button}
            variant="filled"
            color="green"
            type="white"
            disabled={planLimitReached}
          >
            <ButtonDropdownItem onSelect={handleAddNewSubscriber}>
              Add a Single Subscriber
            </ButtonDropdownItem>
            <ButtonDropdownItem onSelect={handleOpenWizard}>Import from CSV</ButtonDropdownItem>
          </DropdownButton>
        )}
        actionModeSettings={{
          edit: {
            modalTitle: 'Edit Subscriber',
            form: ActionForm,
            submitHandler: handleEditSubscriber,
          },
          create: {
            modalTitle: 'Add a New Subscriber',
            form: ActionForm,
            submitHandler: handleAddSubscriber,
          },
        }}
        headerCells={
          <>
            <TableHeading>Email</TableHeading>
            <TableHeading>First Name</TableHeading>
            <TableHeading>Status</TableHeading>
            <TableHeading>Subscription Date</TableHeading>
            <TableHeading />
          </>
        }
        filters={{
          status: 'CONFIRMED',
        }}
        customFilter={{
          targetFilter: 'status',
          nodes: (
            <>
              <ListboxOption value="all">All Subscribers</ListboxOption>
              <ListboxOption value="CONFIRMED">Confirmed Subscribers</ListboxOption>
              <ListboxOption value="PENDING">Pending Subscribers</ListboxOption>
              <ListboxOption value="UNSUBSCRIBED">Unsubscribed Subscribers</ListboxOption>
              <ListboxOption value="BOUNCED">Bounced Subscribers</ListboxOption>
              <ListboxOption value="BLOCKED">Blocked Subscribers</ListboxOption>
            </>
          ),
        }}
      >
        {(rowData) => (
          <>
            <td>
              <TdEditLink rowData={rowData}>
                {rowData.values.find(({asciiName}) => asciiName === 'email')?.value}
              </TdEditLink>
            </td>
            <td>
              <TdEditLink rowData={rowData}>
                {rowData.values.find(({asciiName}) => asciiName === 'first_name')?.value}
              </TdEditLink>
            </td>
            <td>
              <TdEditLink rowData={rowData}>{rowData.subscriberStatus}</TdEditLink>
            </td>
            <td>
              <TdEditLink rowData={rowData}>{rowData.registeredAt}</TdEditLink>
            </td>
          </>
        )}
      </DataTable>
      <ImportSubscribersWizard />
      <Modal onRequestClose={closeExportConfirmModal} isOpen={exportConfirmModalOpen}>
        <ExportModalSuccess />
      </Modal>
      {isAuditModalOpen && <AuditSubscribersBulkModal onRequestClose={toggleAuditModalOpen} />}
    </>
  );
}

export default SubscribersTableContainer;
