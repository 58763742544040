export const initialSelection = {
  selectedRows: [],
  bulkSelected: false,
};

export const initialState = {
  ...initialSelection,
  data: [],
  formatted: {},
  meta: {perPage: 20, page: 1, totalCount: 0, totalPages: 0},
  processing: false,
  loading: true,
  filters: {},
  searchText: undefined,
};

export function setDataReducer(state, action) {
  if (!action.payload) {
    return state;
  }

  return {
    ...state,
    data: action?.payload?.rows || action?.payload?.data?.rows,
    formatted: action?.payload?.formattedHeader || action?.payload?.data?.formattedHeader,
    meta: {
      ...state.meta,
      ...action.payload.meta,
    },
  };
}

export function setMetaReducer(state, action) {
  return {
    ...state,
    meta: {
      ...state.meta,
      ...action.payload,
    },
  };
}

export function searchReducer(state, action) {
  if (!action.payload.response) {
    return state;
  }

  const {text, response} = action.payload;
  return {
    ...state,
    formatted: response.data.formattedHeader,
    data: response.data.rows,
    meta: {
      ...state.meta,
      ...response.meta,
      page: 1,
    },
    selectedRows: [],
    searchText: text,
  };
}

export function goToNextPageReducer(state, action) {
  if (!action.payload) {
    return state;
  }

  const {response} = action.payload;
  return {
    ...state,
    ...initialSelection,
    formatted: response.data.formattedHeader,
    data: response.data.rows,
    meta: {
      ...state.meta,
      ...response.meta,
      page: Number(state.meta.next.page),
    },
  };
}

export function goToPrevPageReducer(state, action) {
  if (!action.payload) {
    return state;
  }

  const {response} = action.payload;
  return {
    ...state,
    ...initialSelection,
    data: response.data.rows,
    formatted: response.data.formattedHeader,
    meta: {
      ...state.meta,
      ...response.meta,
      page: Number(state.meta.prev.page),
    },
  };
}

export function forcePageChangeReducer(state, action) {
  if (!action.payload.response) {
    return state;
  }

  const {page, response} = action.payload;
  return {
    ...state,
    ...initialSelection,
    data: response.data.rows,
    formatted: response.data.formattedHeader,
    meta: {
      ...state.meta,
      ...response.meta,
      page: Number(page),
    },
  };
}

export function changePerPageSizeReducer(state, action) {
  if (!action.payload.response) {
    return state;
  }

  const {perPage, response} = action.payload;
  return {
    ...state,
    data: response.data.rows,
    formatted: response.data.formattedHeader,
    meta: {
      ...state.meta,
      ...response.meta,
      perPage,
      page: 1,
    },
  };
}

export function bulkDeleteReducer(state, action) {
  if (!action.payload) {
    return state;
  }

  const {response} = action.payload;
  return {
    ...state,
    ...initialSelection,
    data: response.data.rows,
    formatted: response.data.formattedHeader,
    meta: {
      ...state.meta,
      ...response.meta,
    },
  };
}

export function selectRowReducer(state, action) {
  return {
    ...state,
    selectedRows: [...state.selectedRows, action.payload],
  };
}

export function deselectRowReducer(state, action) {
  return {
    ...state,
    selectedRows: state.selectedRows.filter((el) => el !== action.payload),
  };
}

export function bulkToggleReducer(state, action) {
  return {
    ...state,
    selectedRows: action.payload ? state.data.map((s) => s.id) : [],
    bulkSelected: action.payload,
  };
}

export function setProcessingReducer(state, action) {
  return {
    ...state,
    processing: action.payload,
  };
}

export function setLoadingReducer(state, action) {
  return {
    ...state,
    loading: action.payload,
  };
}

export function setFetcherReducer(state, action) {
  return {
    ...state,
    fetcher: action.payload,
  };
}

export function setModeReducer(state, action) {
  const {mode, data} = action.payload;
  return {
    ...state,
    mode,
    defaultValues: mode === '' ? {} : data,
  };
}

export function setFilterReducer(state, action) {
  return {
    ...state,
    ...initialSelection,
    filters: {
      ...action.payload,
    },
  };
}
