import {useState} from 'react';
import {Link} from 'react-router-dom';
import styles from '../SubscriberPage.module.scss';
import Tag from './Tag';
import AddTag from './AddTag';
import Modal from '../../../components/Modal';
import AddTagsForm from './AddTagsForm';
import ROUTES from '../../../Router/routes';

function FormCustomTags({tags, selectedTags, setSelectedTags}) {
  const [addTagsOpen, setAddTagsOpen] = useState(false);

  const handleAddTagsToggle = () => {
    setAddTagsOpen(!addTagsOpen);
  };

  const handleDeleteTag = (id) => () => {
    setSelectedTags(selectedTags.filter((v) => v !== id));
  };

  const handleAddTagsSubmit = (ids) => {
    setSelectedTags(ids);
  };

  return (
    <>
      <h3 className={styles.formSubtitle}>tags</h3>
      {tags.length === 0 && (
        <p className={styles.emptyText}>
          You don&apos;t have any defined tags. To add some, go{' '}
          <Link to={ROUTES.settings.subscriberFields}>here</Link>.
        </p>
      )}
      {tags.length > 0 && (
        <div className={styles.tagsWrapper}>
          {tags
            .filter((v) => selectedTags.indexOf(v.id) > -1)
            .map((property) => (
              <Tag
                key={property.id}
                name={property.name}
                onClick={handleDeleteTag(property.id)}
                selected
              />
            ))}
          <AddTag hasTags={selectedTags.length > 0} openAddTags={handleAddTagsToggle} />
        </div>
      )}
      <Modal onRequestClose={handleAddTagsToggle} isOpen={addTagsOpen} title="Add Tags">
        <AddTagsForm
          tags={tags}
          selectedTags={selectedTags}
          onSubmit={handleAddTagsSubmit}
          onRequestClose={handleAddTagsToggle}
        />
      </Modal>
    </>
  );
}

export default FormCustomTags;
