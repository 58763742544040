import cx from 'clsx';
import PropTypes from 'prop-types';
import styles from './FieldError.module.scss';

function FieldError({error, absolute}) {
  return (
    <span className={cx(styles.msg, styles.red, {[styles.absolute]: absolute})}>
      <span>{typeof error === 'string' ? error : error.message}</span>
    </span>
  );
}

FieldError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.bool])
    .isRequired,
  absolute: PropTypes.bool,
};

export default FieldError;
