import React from 'react';
import {SubscriptionFormBuilderContext} from '../context';

export const useEnrichedField = (field) => {
  const {subscriberFields} = React.useContext(SubscriptionFormBuilderContext);

  return React.useMemo(() => {
    if (!field) {
      return null;
    }
    const subscriberField = subscriberFields?.find((i) => i.asciiName === field.name);
    if (!subscriberField) {
      return field;
    }

    return {
      ...field,
      ...subscriberField,
    };
  }, [field, subscriberFields]);
};
