import {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import {handleErrorAlert} from '../../../utils/handleErrorAlert';
import useAlert from '../../alerts/useAlert';
import useRouter from '../../../hooks/useRouter';
import Spinner from '../../../components/Spinner';
import Newsletter from './Newsletter';
import {fetchConfirmedSubscribersService} from '../../subscribers/service';
import useSaveDraft from '../hooks/useSaveDraft';
import useSendNewsletter from '../hooks/useSendNewsletter';
import ROUTES from '../../../Router/routes';
import {fetchDomainService, fetchSenderOptionsService} from '../../settings/email-sender/service';
import useFetchNewsletter from '../hooks/useFetchNewsletter';
import useAuditStatus from '../../audit-status/useAuditStatus';
import {useFreePlanModals} from '../../free-plan-info/hooks/useFreePlanModals';
import Toolbar from './Toolbar/Toolbar';

function NewsletterContainer() {
  const {history, query} = useRouter();
  const {showAlert} = useAlert();
  const [domainStatus, setDomainStatus] = useState();
  const [testModalOpen, setTestModalOpen] = useState(false);
  const [confirmedSubscribers, setConfirmedSubscribers] = useState();
  const [newsletterID, setNewsletterID] = useState(query.id);
  const [data] = useFetchNewsletter();
  const {saveDraft} = useSaveDraft(newsletterID);
  const {sendNewsletter} = useSendNewsletter(newsletterID);
  const [sendProcessing, setSendProcessing] = useState(false);
  const [draftProcessing, setDraftProcessing] = useState(false);
  const [sendTestProcessing, setSendTestProcessing] = useState(false);
  const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);

  const {
    isFreePlanModalOpen,
    FreePlanModal,
    checkFreePlanVerified,
    FreePlanVerificationError,
  } = useFreePlanModals();
  const {
    isAuditModalOpen,
    toggleAuditModalOpen,
    AuditStatusCheckError,
    auditStatus,
    isAuditOk,
    recheckAuditStatusPassed,
  } = useAuditStatus();

  const isSendAvailable = isAuditOk(auditStatus);

  const {run: fetchConfirmedSubscribersRequest} = useFetch(fetchConfirmedSubscribersService, {
    throwError: true,
  });
  const {data: senderOptionsData} = useFetch(fetchSenderOptionsService, {
    immediate: true,
  });
  const {run: fetchDomainRequest} = useFetch(fetchDomainService, {throwError: true});

  useEffect(
    function () {
      async function fetchData() {
        try {
          const response = await fetchDomainRequest(null);
          setDomainStatus(response?.verificationStatus);
        } catch (error) {
          handleErrorAlert(error, showAlert);
        }
      }

      fetchData();
    },
    [fetchDomainRequest]
  );

  useEffect(
    function () {
      async function fetchSubscribers() {
        const response = await fetchConfirmedSubscribersRequest(null);
        setConfirmedSubscribers(response?.count);
      }

      fetchSubscribers();
    },
    [fetchConfirmedSubscribersRequest]
  );

  function handleTestModalClose() {
    setTestModalOpen(false);
  }

  async function handleSendNewsletter(values, setError) {
    try {
      setSendProcessing(true);
      checkFreePlanVerified();
      const fromDraft = await saveDraft(values);
      await recheckAuditStatusPassed(fromDraft);
      await sendNewsletter(fromDraft, values);
      history.push(ROUTES.newsletters.index, {
        alert: {
          message: `Campaign has been ${values.scheduleTime ? 'scheduled' : 'sent'}!`,
          options: {
            variant: 'success',
          },
        },
      });
    } catch (error) {
      if (error instanceof AuditStatusCheckError) {
        toggleAuditModalOpen();
      } else if (error instanceof FreePlanVerificationError) {
        // appropriate modal is shown by checkFreePlanVerified
      } else {
        handleErrorAlert(error, showAlert, setError);
      }
    } finally {
      setSendProcessing(false);
    }
  }

  async function handleSendTest(values, setError) {
    try {
      setSendTestProcessing(true);
      checkFreePlanVerified();
      const fromDraft = await saveDraft(values);
      await recheckAuditStatusPassed(fromDraft);
      setNewsletterID(fromDraft);
      setTestModalOpen(true);
    } catch (error) {
      if (error instanceof AuditStatusCheckError) {
        toggleAuditModalOpen();
        setSendTestProcessing(false);
      } else if (!(error instanceof FreePlanVerificationError)) {
        handleErrorAlert(error, showAlert, setError);
      }
    } finally {
      setSendTestProcessing(false);
    }
  }

  async function handleSaveDraft(values, setError) {
    try {
      const fromDraft = await saveDraft(values);
      setNewsletterID(fromDraft);
      history.push(ROUTES.newsletters.index, {
        alert: {
          message: 'Draft has been saved!',
          options: {
            variant: 'success',
          },
        },
      });
    } catch (error) {
      handleErrorAlert(error, showAlert, setError);
    } finally {
      setDraftProcessing(false);
    }
  }

  async function handleFormSubmit(values, submitType, setError) {
    switch (submitType) {
      case 'send':
        return handleSendNewsletter(values, setError);
      case 'send-test':
        return handleSendTest(values, setError);
      default:
        return handleSaveDraft(values, setError);
    }
  }

  if (Object.keys(query).length && !data) {
    return <Spinner height={400} />;
  }

  if (data?.status === 'SENT') {
    return <Redirect to={`${ROUTES.newsletters.sent}/${data.id}`} />;
  }

  return (
    <div style={{display: 'flex'}}>
      <div style={{flex: 1}}>
        <Newsletter
          onSubmit={handleFormSubmit}
          id={newsletterID}
          data={data}
          domainStatus={domainStatus}
          draftProcessing={draftProcessing}
          sendTestProcessing={sendTestProcessing}
          sendProcessing={sendProcessing}
          confirmedSubscribers={confirmedSubscribers}
          closeTestModal={handleTestModalClose}
          testModalOpen={testModalOpen}
          senderOptions={senderOptionsData}
          isFreePlanModalOpen={isFreePlanModalOpen}
          FreePlanModal={FreePlanModal}
          disabled={!isSendAvailable}
          isAuditModalOpen={isAuditModalOpen}
          toggleAuditModalOpen={toggleAuditModalOpen}
          isScheduleModalOpen={isScheduleModalOpen}
          setScheduleModalOpen={setScheduleModalOpen}
        />
      </div>
      <Toolbar />
    </div>
  );
}

export default NewsletterContainer;
