import escapeHtml from 'escape-html';
import {css} from '@emotion/css';
import {Text} from 'slate';

export const Leaf = ({node}) => {
  const {text} = node;

  if (node.fontSize || node.color) {
    return (
      <span
        style={{
          ...(node.fontSize && {fontSize: node.fontSize}),
          ...(node.color && {color: node.color}),
        }}
      >
        {text}
      </span>
    );
  }

  if (node.bold) {
    return <strong>{text}</strong>;
  }

  if (node.italic) {
    return <em>{text}</em>;
  }

  if (node.underline) {
    return <u>{text}</u>;
  }

  if (node.code) {
    return <code>{text}</code>;
  }

  return <>{text}</>;
};

export const Element = ({node, customTypes, children}) => {
  const style = {
    ...(node.align && {textAlign: node.align}),
    ...(node.backgroundColor && {backgroundColor: node.backgroundColor}),
    ...node.style,
  };

  switch (node.type) {
    case 'block-quote':
      return (
        <blockquote style={style} data-type={node.type}>
          <p>{children}</p>
        </blockquote>
      );
    case 'paragraph':
      return (
        <p style={style} data-type={node.type}>
          {children}
        </p>
      );
    case 'linkButton': {
      const {LinkButton} = customTypes;
      return LinkButton ? (
        <LinkButton id={node.id} href={escapeHtml(node.href)} nodeStyle={node.style}>
          {children}
        </LinkButton>
      ) : (
        <></>
      );
    }
    case 'image': {
      return (
        <Image
          url={escapeHtml(node.url)}
          objectKey={escapeHtml(node.objectKey)}
          nodeStyle={node.style}
        >
          {children}
        </Image>
      );
    }
    case 'link': {
      return (
        <a
          style={style}
          data-type={node.type}
          href={escapeHtml(node.url)}
          rel="noreferrer"
          target="_blank"
          data-id={node.tag}
        >
          {children}
        </a>
      );
    }
    case 'bulleted-list':
      return (
        <ul style={style} data-type={node.type}>
          {children}
        </ul>
      );
    case 'numbered-list':
      return (
        <ol style={style} data-type={node.type}>
          {children}
        </ol>
      );
    case 'list-item':
      return (
        <li style={style} data-type={node.type}>
          {children}
        </li>
      );
    case 'table':
      return (
        <table style={style} data-type={node.type}>
          {children}
        </table>
      );
    case 'tbody':
      return (
        <tbody style={style} data-type={node.type}>
          {children}
        </tbody>
      );
    case 'table-row':
      return (
        <tr style={style} data-type={node.type}>
          {children}
        </tr>
      );
    case 'table-cell':
      return (
        <td style={style} data-type={node.type}>
          {children}
        </td>
      );
    default:
      return children;
  }
};

const Image = ({url, objectKey, nodeStyle}) => {
  return (
    <table data-type="image" data-url={url} data-objectKey={objectKey} style={nodeStyle || {}}>
      <tr
        contentEditable={false}
        className={css`
          user-select: none;
        `}
      >
        <td>
          <img
            alt=""
            src={url}
            style={{
              display: 'block',
              maxWidth: '100%',
              maxHeight: 300,
            }}
          />
        </td>
      </tr>
    </table>
  );
};

const StaticEmailNode = ({node, customTypes}) => {
  if (Text.isText(node)) {
    return <Leaf node={node} />;
  }

  const childrenNodes = node.children.map((n) => (
    <StaticEmailNode node={n} customTypes={customTypes} />
  ));

  return (
    <Element node={node} customTypes={customTypes}>
      {childrenNodes}
    </Element>
  );
};

export default StaticEmailNode;
