import {useState} from 'react';
import useFetch from '../../../hooks/useFetch';
import {saveDraftService, updateDraftService} from '../service';

function useSaveDraft(newsletterID) {
  const [draftProcessing, setDraftProcessing] = useState(false);

  const {run: saveDraftRequest} = useFetch(saveDraftService, {throwError: true});
  const {run: updateDraftRequest} = useFetch(updateDraftService, {throwError: true});

  async function saveDraft(values) {
    setDraftProcessing(true);
    try {
      if (newsletterID) {
        await updateDraftRequest(values);
        return newsletterID;
      }

      const response = await saveDraftRequest(values);
      return response.id;
    } finally {
      setDraftProcessing(false);
    }
  }

  return {draftProcessing, saveDraft};
}

export default useSaveDraft;
