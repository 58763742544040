import cx from 'clsx';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Card from '../../../components/Card';
import Button from '../../../components/Button';
import BackButton from '../../../components/BackButton';
import ROUTES from '../../../Router/routes';

import {newsletterContentPropType} from '../../../utils/prop-types/newsletter';
import styles from './FailedNewsletter.module.scss';

function FailedNewsletter({createFromDraft, data}) {
  async function handleCreateFromDraft() {
    createFromDraft(data.id);
  }

  return (
    <div>
      <BackButton noMargin to={ROUTES.newsletters.index}>
        Newsletter Campaigns
      </BackButton>
      <div className={styles.btns}>
        <Button type="button" variant="outlined" color="green" onClick={handleCreateFromDraft}>
          Save as Draft
        </Button>
      </div>
      <Card noPadding>
        <div className={styles.info}>
          <div className={styles.row}>
            <strong>Subject:</strong>
            <span>{data.subject}</span>
            <span className={cx(styles.supplementary, styles.supplementaryAside)}>
              {data.transmissionTime}
            </span>
          </div>
        </div>
        <div className={styles.content} dangerouslySetInnerHTML={{__html: data.content}} />
      </Card>
    </div>
  );
}

FailedNewsletter.propTypes = {
  data: newsletterContentPropType,
  createFromDraft: PropTypes.func.isRequired,
};

export default FailedNewsletter;
