import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';
import BodyContainer from '../BodyContainer';
import styles from './BoardingWizard.module.scss';

function BoardingWizard({isOpen, onRequestClose}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      size="full"
    >
      <div className={styles.wizard} id="wizard">
        <BodyContainer onRequestClose={onRequestClose} />
      </div>
    </Modal>
  );
}

BoardingWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default BoardingWizard;
