import {Helmet} from 'react-helmet';
import FailedNewsletterContainer from './FailedNewsletterContainer';

function FailedNewsletterPage() {
  return (
    <>
      <Helmet title="Newsletter Campaign" />
      <FailedNewsletterContainer />
    </>
  );
}

export default FailedNewsletterPage;
