import {useEffect, useState} from 'react';
import ButtonDropdown from '../../../../../../components/ButtonDropdown';
import ButtonDropdownItem from '../../../../../../components/ButtonDropdown/ButtonDropdownItem';
import useFetch from '../../../../../../hooks/useFetch';
import {fetchSubscriberFieldsService} from '../../../../subscriber-fields/service';

const AddAsciiName = ({onChange}) => {
  const {run: fetchSubscriberFieldsRequest} = useFetch(fetchSubscriberFieldsService, {
    throwError: true,
  });
  const [subscriberFields, setSubscriberFields] = useState();

  useEffect(() => {
    const getSubscriberFields = async () => {
      const subFields = await fetchSubscriberFieldsRequest();
      setSubscriberFields(subFields);
    };
    getSubscriberFields();
  }, []);

  const addAsciiName = (asciiName) => {
    onChange(asciiName);
  };

  return (
    <ButtonDropdown
      buttonText="Personalization Tag"
      popoverStyle={{maxHeight: 200, overflow: 'scroll'}}
      style={{height: 24, fontSize: 14}}
    >
      {subscriberFields &&
        subscriberFields.map((field) => (
          <ButtonDropdownItem onSelect={() => addAsciiName(field.asciiName)}>
            {field.name}
          </ButtonDropdownItem>
        ))}
    </ButtonDropdown>
  );
};

export default AddAsciiName;
