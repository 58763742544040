import {Switch, Route, Redirect} from 'react-router-dom';
import ROUTES from '../../Router/routes';
import PageNotFound from '../../Router/PageNotFound';
import NewsletterReportPage from './report/NewsletterReportPage';
import NewsletterPage from './newsletter/NewsletterPage';
import OverviewPage from './overview/OverviewPage';
import SentNewsletterPage from './sent-newsletter/SentNewsletterPage';
import FailedNewsletterPage from './failed-newsletter/FailedNewsletterPage';
import NewsletterTemplatesPage from './templates/NewsletterTemplatesPage';
import {NewsletterProvider} from './context';
import TemplateEditor from './templates/TemplateEditor';

function NewslettersRouter() {
  return (
    <NewsletterProvider>
      <Switch>
        <Route path={ROUTES.newsletters.index} exact>
          <OverviewPage />
        </Route>
        <Route path={`${ROUTES.newsletters.report}/:id`}>
          <NewsletterReportPage />
        </Route>
        <Route path={`${ROUTES.newsletters.sent}/:id`}>
          <SentNewsletterPage />
        </Route>
        <Route path={`${ROUTES.newsletters.failed}/:id`}>
          <FailedNewsletterPage />
        </Route>
        <Route path={ROUTES.newsletters.sent}>
          <Redirect to={ROUTES.newsletters.index} />
        </Route>
        <Route path={ROUTES.newsletters.create}>
          <NewsletterPage />
        </Route>
        <Route path={ROUTES.newsletters.templateEditor}>
          <TemplateEditor />
        </Route>
        <Route path={ROUTES.newsletters.templates}>
          <NewsletterTemplatesPage />
        </Route>
        <Route path={`${ROUTES.newsletters.edit}/:id`}>
          <NewsletterPage />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </NewsletterProvider>
  );
}

export default NewslettersRouter;
