/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {useState} from 'react';
import {
  getCaretCharacterOffsetWithin,
  getFooterBackground,
  renderFormSubmitButton,
  setCaret,
} from '../../helper';

import AddNewFieldStyles from '../Toolbar/AddNewFieldStyles';
import AddNewTag from '../Toolbar/AddNewTag';
import FormField from './FormField';
import Modal from '../../../../components/Modal';
import {SubscriptionFormBuilderContext} from '../../context';
import clsx from 'clsx';
import {getTextStyles} from '../../service';
import logo from '../../../../img/logolight_small.svg';
import styles from './FormPreview.module.scss';
import {useHandleConfigParam} from '../../hooks/useHandleConfigParam';
import {useFreePlanModals} from '../../../free-plan-info/hooks/useFreePlanModals';
import {useFreePlan} from '../../../free-plan-info/context/FreePlanContext';

function FormPreviewContent() {
  const {
    setSelectedElement,
    selectedElement,
    formState,
    setShowAddNewFieldModal,
    showAddNewFieldModal,
    showAddNewTagModal,
    setShowAddNewTagModal,
  } = React.useContext(SubscriptionFormBuilderContext);

  const {state} = useFreePlan();

  const [paramPath, setParamPath] = useState();

  const [value, handleChange] = useHandleConfigParam(paramPath);

  const updateJsonInput = async (e) => {
    const offset = getCaretCharacterOffsetWithin(e.target);
    await handleChange(e.target.textContent);
    setCaret(e.target, offset);
  };

  const getFooterBorderRadius = () => {
    return formState.type !== 'SLIDER'
      ? {
          borderBottomRightRadius:
            parseInt(formState.jsonConfig.mainBorder.radius, 10) >
            parseInt(formState.jsonConfig.mainBorder.size, 10)
              ? parseInt(formState.jsonConfig.mainBorder.radius, 10) -
                parseInt(formState.jsonConfig.mainBorder.size, 10)
              : 0,
          borderBottomLeftRadius:
            parseInt(formState.jsonConfig.mainBorder.radius, 10) >
            parseInt(formState.jsonConfig.mainBorder.size, 10)
              ? parseInt(formState.jsonConfig.mainBorder.radius, 10) -
                parseInt(formState.jsonConfig.mainBorder.size, 10)
              : 0,
        }
      : {
          borderRadius: 0,
        };
  };

  return (
    <>
      <Modal
        isOpen={showAddNewFieldModal}
        onRequestClose={() => setShowAddNewFieldModal(false)}
        title="Add Form Field"
      >
        <AddNewFieldStyles />
      </Modal>
      <Modal
        isOpen={showAddNewTagModal}
        onRequestClose={() => setShowAddNewTagModal(false)}
        title="Add Tag"
      >
        <AddNewTag />
      </Modal>
      {formState.jsonConfig.body.progressBarType === '50%' && (
        <>
          <div
            className={clsx(
              'md-embedded-form--progress-bar-type-percent',
              styles.selectableFrame,
              selectedElement === 'progressBar_type' && styles.selectableFrameActive
            )}
            onClick={() => setSelectedElement('progressBar_type')}
            style={getTextStyles(formState.jsonConfig.body.progressBarPercentStyle.text)}
          >
            {formState.jsonConfig.body.progressBarPercentStyle?.text?.value || ''}
            <div className={styles.percentBarWrapper}>
              <div
                className={styles.percentBar}
                style={{
                  backgroundColor:
                    formState.jsonConfig.body.progressBarPercentStyle.backgroundColor,
                }}
              />
            </div>
          </div>
        </>
      )}
      {formState.jsonConfig.body.progressBarType === '1/2' && (
        <>
          <div
            className={clsx(
              'md-embedded-form--slider-type-step',
              styles.selectableFrame,
              selectedElement === 'progressBar_type' && styles.selectableFrameActive
            )}
            onClick={() => setSelectedElement('progressBar_type')}
          >
            <div className={styles.stepsWrapper}>
              <div
                className={styles.stepOneContainer}
                style={{
                  backgroundColor:
                    formState.jsonConfig.body.progressBarStepStyle?.backgroundColorActive,
                }}
              >
                <span className={styles.stepIndicator}>1</span>{' '}
                {formState.jsonConfig.body.progressBarStepStyle?.text?.stepOneValue || ''}
              </div>
              <div
                className={styles.stepTwoContainer}
                style={{
                  backgroundColor:
                    formState.jsonConfig.body.progressBarStepStyle?.backgroundColorInactive,
                }}
              >
                <span className={styles.stepIndicator}>2</span>{' '}
                {formState.jsonConfig.body.progressBarStepStyle?.text?.stepTwoValue || ''}
              </div>
            </div>
          </div>
        </>
      )}
      <h1
        className={clsx(
          'md-embedded-form--title',
          styles.selectableFrame,
          selectedElement === 'header' && styles.selectableFrameActive
        )}
        style={getTextStyles(formState.jsonConfig.header.text)}
        onClick={() => {
          setSelectedElement('header');
          setParamPath('jsonConfig.header.text.value');
        }}
        onInput={updateJsonInput}
        suppressContentEditableWarning
      >
        {formState.jsonConfig.header.text.value}
      </h1>
      <p
        className={clsx(
          'md-embedded-form--text',
          styles.selectableFrame,
          selectedElement === 'body' && styles.selectableFrameActive
        )}
        onClick={() => {
          setSelectedElement('body');
          setParamPath('jsonConfig.body.text.value');
        }}
        onInput={updateJsonInput}
        suppressContentEditableWarning
        style={getTextStyles(formState.jsonConfig.body.text)}
      >
        {formState.jsonConfig.body.text.value}
      </p>
      <div className="md-embedded-form--form" style={{marginBottom: state.freePlan ? 80 : 0}}>
        {formState.inputFields
          .filter(({name}) => name)
          .map((field) => (
            <FormField
              key={field.name}
              field={field}
              setParamPath={setParamPath}
              updateJsonInput={updateJsonInput}
            />
          ))}
        <div
          className={
            formState.type === 'POPUP'
              ? styles.addCustomInputButtonForPopup
              : styles.addCustomInputButton
          }
          onClick={() => setShowAddNewFieldModal(true)}
        >
          <span style={{fontSize: 25, marginRight: 9, fontWeight: 400}}>+</span> Add Form Field
        </div>
        {formState.subscriberCounter.enabled && formState.type === 'POPUP' && (
          <p
            className={clsx(
              'md-embedded-form--subscriber-counter--text',
              styles.selectableFrame,
              styles.subscriberCount,
              selectedElement === 'subscriber_counter' && styles.selectableFrameActive
            )}
            onClick={() => setSelectedElement('subscriber_counter')}
            style={getTextStyles(formState.jsonConfig.body.subscriberCounterStyle.text)}
          >
            <span>{`${formState.subscriberCounter.offset} ` || '0 '}</span>
            <span
              onClick={() => {
                setParamPath('jsonConfig.body.subscriberCounterStyle.text.value');
              }}
              onInput={updateJsonInput}
              style={{outline: 'none'}}
              suppressContentEditableWarning
            >
              {formState.jsonConfig.body.subscriberCounterStyle?.text?.value || ''}
            </span>
          </p>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            onClick={() => {
              setSelectedElement('button');
              setParamPath('jsonConfig.button.text.value');
            }}
            className={clsx(
              styles.selectableFrame,
              selectedElement === 'button' && styles.selectableFrameActive
            )}
            style={{
              width: formState.jsonConfig.button.width ? formState.jsonConfig.button.width : '100%',
            }}
          >
            {renderFormSubmitButton(formState.jsonConfig.button, formState, true, updateJsonInput)}
          </div>
        </div>
        {formState.subscriberCounter.enabled && formState.type !== 'POPUP' && (
          <p
            className={clsx(
              'md-embedded-form--subscriber-counter--text',
              styles.selectableFrame,
              styles.subscriberCount,
              selectedElement === 'subscriber_counter' && styles.selectableFrameActive
            )}
            onClick={() => setSelectedElement('subscriber_counter')}
            style={getTextStyles(formState.jsonConfig.body.subscriberCounterStyle.text)}
          >
            <span>{`${formState.subscriberCounter.offset} ` || '0 '}</span>
            <span
              onClick={() => {
                setParamPath('jsonConfig.body.subscriberCounterStyle.text.value');
              }}
              style={{outline: 'none'}}
              onInput={updateJsonInput}
              suppressContentEditableWarning
            >
              {formState.jsonConfig.body.subscriberCounterStyle?.text?.value || ''}
            </span>
          </p>
        )}
        <p
          className={clsx(
            'md-embedded-form--footer--text',
            styles.selectableFrame,
            selectedElement === 'footer' && styles.selectableFrameActive
          )}
          onClick={() => {
            setSelectedElement('footer');
            setParamPath('jsonConfig.footer.text.value');
          }}
          onInput={updateJsonInput}
          suppressContentEditableWarning
          style={getTextStyles(formState.jsonConfig.footer.text)}
        >
          {formState.jsonConfig.footer.text.value}
        </p>
      </div>
      {state.freePlan && (
        <div
          className={clsx(styles.brandingFrame)}
          style={{...getFooterBackground(formState), ...getFooterBorderRadius()}}
        >
          <div className={styles.brandingName}>POWERED BY</div>
          <img width="126" src={logo} className={styles.logo} alt="Maildroppa Logo" />
        </div>
      )}
    </>
  );
}

export default FormPreviewContent;
